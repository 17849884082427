import React, { useEffect, useRef } from "react";
import styles from "./SectionHome4.module.scss";
import Carousel from "./SectionHome4Carousel";

const SectionHome4 = ({onlyCarousel = false}) => {

  const carouselRef = useRef(null);
  useEffect(() => {
    new Carousel({
      carousel: carouselRef.current
    });
  }, [carouselRef]);

  const renderCarousel = (carouselBlack = false) => {
    const className = carouselBlack ? `${styles.carousel} ${styles.carouselBlack}` : `${styles.carousel}`;
    return (
      <div className={className} ref={carouselRef}>
        <div className={styles.line}>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-01.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-02.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-03.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-04.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-05.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-06.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-07.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-08.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-09.png"
              alt="edgardo-partners"
            />
          </div>
          <div>
            <img
              className={styles.CarrouselLogos}
              src="/carrousel/logo-10.png"
              alt="edgardo-partners"
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
    {onlyCarousel && (
      <>{renderCarousel(true)}</>
    )}

    {!onlyCarousel && (
      <div className={styles.Container}>
      <div className={styles.Image}>
        <img src="/homepage/homev2-bottom.png" alt="" />
      </div>

      <div className={styles.Claim}>
        <div className={styles.BottomTextSpan}>
          <span className={styles.BottomTextSpanOutlined}>Edgardo Giménez</span>
          <span>referente</span>
          <span>del arte pop</span>
          <span>latinoamericano</span>
        </div>
      </div>

      {renderCarousel()}
    </div>
    )}
    </>
  );
};

export default SectionHome4;
