import React from "react";
import "./MainLayout.scss";
import { Outlet } from "react-router-dom";
import NavbarV2 from "../pages/home/NavbarV2";
import ModalCart from "../components/shared/ModalCart";
import FooterV2 from "../components/shared/FooterV2";

const MainLayout = ({ children, navbarBackground='white' }) => {
  return (
    <>
      <div className="main-layout">
        {/* <Navbar /> */}
        <main className="main-wrapper">
          <NavbarV2 navbarBackground={navbarBackground} />
          <ModalCart />
          <Outlet />
        </main>
      </div>
      <FooterV2 />
    </>
  );
};

export default MainLayout;
