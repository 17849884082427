import * as React from "react"
const CheckRoundedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M13 0a13 13 0 1 0 13 13A13.013 13.013 0 0 0 13 0Zm5.707 10.707-7 7a1.001 1.001 0 0 1-1.415 0l-3-3a1 1 0 1 1 1.416-1.415L11 15.587l6.293-6.293a1 1 0 1 1 1.415 1.415Z"
    />
  </svg>
)
export default CheckRoundedIcon