import React from "react";

function GiftIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2044_2033)">
        <path d="M16.667 9.99996V18.3333H3.33366V9.99996M10.0003 18.3333V5.83329M10.0003 5.83329H6.25033C5.69779 5.83329 5.16789 5.6138 4.77719 5.2231C4.38649 4.8324 4.16699 4.30249 4.16699 3.74996C4.16699 3.19743 4.38649 2.66752 4.77719 2.27682C5.16789 1.88612 5.69779 1.66663 6.25033 1.66663C9.16699 1.66663 10.0003 5.83329 10.0003 5.83329ZM10.0003 5.83329H13.7503C14.3029 5.83329 14.8328 5.6138 15.2235 5.2231C15.6142 4.8324 15.8337 4.30249 15.8337 3.74996C15.8337 3.19743 15.6142 2.66752 15.2235 2.27682C14.8328 1.88612 14.3029 1.66663 13.7503 1.66663C10.8337 1.66663 10.0003 5.83329 10.0003 5.83329ZM1.66699 5.83329H18.3337V9.99996H1.66699V5.83329Z" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2044_2033">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
export default GiftIcon;

