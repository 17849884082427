import React, { useRef, useEffect, useState } from 'react';
import jsonData from "./ArworkLandingParallax.json"

export default function ArtworkLandingParallax() {

  const [showModal, setShowModal] = useState(false);

  const parallaxScrollerRef = useRef(null);
  let srcImgModal = useRef(null);
  let titleModal = useRef(null);
  let textModal = useRef(null);

  const handleClickImage = (e) => {
    const jsonName = e.target.dataset.json_name;
    if(!jsonData[jsonName])
      return;


    titleModal.current = jsonData[jsonName].name;
    textModal.current = jsonData[jsonName].description;
    srcImgModal.current = jsonData[jsonName].image;
    setTimeout(() => {
      setShowModal(true);
    }, 250);
  }

  useEffect(() => {
    if(!parallaxScrollerRef) return;

    const parallaxItems = document.getElementsByClassName('parallax-item');
    const lastItem = parallaxItems[parallaxItems.length - 1];


    const handleParallaxScroll = () => {

      const currentScroll = window.scrollY;

      for(let item of parallaxItems) {

        const slownewss = item.dataset.slowness;
        const startTop = item.dataset.top;
        const left = item.dataset?.left || '0px';

        const calcScroll = parseFloat(startTop) + ((parseFloat(currentScroll) * slownewss) / 80);
        item.style.transform = `translate3d(${left}, ${calcScroll}px, 0px)`;

        if(window.innerWidth >= 992) {
          const lastItemStyle = getComputedStyle(lastItem);
          const lastItemTransform = lastItemStyle.transform;
          const calcHeight = lastItemTransform.split(',');
          const height = parseFloat(calcHeight[5].replace(')', ''));

          parallaxScrollerRef.current.style.height = height + (height * 0.1) + 'px';
        }
      }
    }

    window.addEventListener('scroll', handleParallaxScroll);

  }, [parallaxScrollerRef]);

  const handleOnMouseEnter = (e) => {
    console.log()
    return;
  }


  return (
    <>

      {showModal && (
        <div className="artworkLandingModal">
          <div className="box">
            <div className="top">
              <button type="button" onClick={() => {
                setShowModal(false);
              }}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 2L16 16" stroke="#0A0A0A" stroke-width="4" stroke-linecap="round"/>
                  <path d="M2 2L16 16" stroke="#0A0A0A" stroke-width="4" stroke-linecap="round"/>
                  <path d="M2 16L16 2" stroke="#0A0A0A" stroke-width="4" stroke-linecap="round"/>
                  <path d="M2 16L16 2" stroke="#0A0A0A" stroke-width="4" stroke-linecap="round"/>
                </svg>
              </button>
            </div>
            <div className="center">
              <img src={srcImgModal.current} />
            </div>
            <div className="bottom">
              <p className="title">
                {titleModal.current}
              </p>
              <p>
                {textModal.current}
              </p>
              <div className="buttons">
                <button type="button">Ir al Shop</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="parallax-container">
        <div className="parallax-scroller" ref={parallaxScrollerRef} style={{minHeight: "100vh"}}>
          <div className="container position-relative">


            <div
              className="title-one parallax-item"
              style={{ left: "50%", transform: "translate3d(-50%, 100px, 0px)" }}
              data-top="100"
              data-left="-50%"
              data-slowness="10">
              <h1>PROCESO DE CREACIÓN</h1>
              <p>La creación de las Fancy Monas demandó más de un año de investigación y desarrollo artístico-tecnológico.</p>
            </div>

            <div
              className="title-two parallax-item"
              style={{ transform: `translate3d(0px, ${window.innerHeight * 1.5}px, 0px)` }}
              data-top={window.innerHeight * 1.5}
              data-slowness="8">
              <h2>Giménez trabajó con un equipo de curadores para explorar su archivo artístico.</h2>
              <p>De allí seleccionó los 113 rasgos que componen las Fancy Monas.</p>
            </div>

            <div
              className="title-three parallax-item"
              style={{ transform: `translate3d(0px, ${window.innerHeight * 3}px, 0px)` }}
              data-top={window.innerHeight * 3}
              data-slowness="2">
              <h2>De allí seleccionó los 113 rasgos que componen la colección.</h2>
              <p>Cada rasgo es una referencia a la historia del artista: ojos, bocas, peinados, trajes, pelajes, fondos y accesorios.</p>
            </div>

            <img
                className="parallax-image parallax-item one"
                src="/artworkLanding/Traje Afrika-01.png"
                onClick={(e) => { handleClickImage(e); }}
                style={{ transform: `translate3d(0px, 150px, 0px)` }}
                data-top={150}
                data-slowness="30"
                data-json_name="TrajeAfrika"
            />

            <img
              className="parallax-image parallax-item two"
              src="/artworkLanding/Traje Mix&Match-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 1000px, 0px)` }}
              data-top={1000}
              data-slowness="0"
              data-json_name="TrajeMix&Match"
            />

            <img
              className="parallax-image parallax-item three"
              src="/artworkLanding/Traje Genial-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 1000px, 0px)` }}
              data-top={1000}
              data-slowness="10"
              data-json_name="TrajeGenial"
            />

            <img
              className="parallax-image parallax-item four"
              src="/artworkLanding/1d7b7e352de171a987b4d4553a92a59b.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 1100, 0px)` }}
              data-top={1100}
              data-slowness="24"
            />

            <img
              className="parallax-image parallax-item five"
              src="/artworkLanding/Vestido Ludovica-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 2300px, 0px)` }}
              data-top={2300}
              data-slowness="0"
              data-json_name="VestidoLudovica"
            />

            <img
              className="parallax-image parallax-item six"
              src="/artworkLanding/Traje Corbata-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 2300px, 0px)` }}
              data-top={2300}
              data-slowness="10"
              data-json_name="TrajeCorbata"
            />

            <img
              className="parallax-image parallax-item seven"
              src="/artworkLanding/Traje Platino-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 2200px, 0px)` }}
              data-top={2200}
              data-slowness="20"
              data-json_name="TrajePlatino"
            />

            <img
              className="parallax-image parallax-item eight"
              src="/artworkLanding/Traje Animal Print-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 2600px, 0px)` }}
              data-top={2600}
              data-slowness="20"
              data-json_name="TrajeAnimal"
            />

            <img
              className="parallax-image parallax-item nine"
              src="/artworkLanding/Traje Naval-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 3400px, 0px)` }}
              data-top={3400}
              data-slowness="12"
              data-json_name="TrajeNaval"
            />

            <img
              className="parallax-image parallax-item ten"
              src="/artworkLanding/Traje Camisa Estrella-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 4000px, 0px)` }}
              data-top={4000}
              data-slowness="5"
              data-json_name="TrajeCamisaEstrella"
            />

            <img
              className="parallax-image parallax-item eleven"
              src="/artworkLanding/Peinado Picapiedra-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 3500px, 0px)` }}
              data-top={3500}
              data-slowness="20"
              data-json_name="PeinadoPicapiedra"
            />

            <img
              className="parallax-image parallax-item twelve"
              src="/artworkLanding/Traje Chaleco Bordado-01.png"
              onClick={(e) => { handleClickImage(e); }}
              style={{ transform: `translate3d(0px, 5000px, 0px)` }}
              data-top={5000}
              data-slowness="0"
              data-json_name="TrajeChalecoBordado"
            />
          </div>
        </div>
      </div>
    </>
  )
}
