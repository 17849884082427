import * as React from "react";

const InfoCircleIcon = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    fill="none"
    viewBox="0 0 26 26"
    onClick={onClick}
  >
    <g stroke="#fff" strokeWidth="0.856" filter="url(#filter0_dd_1201_50177)">
      <rect
        width="19.144"
        height="19.144"
        x="3.428"
        y="2.428"
        rx="9.572"
      ></rect>
      <circle cx="13" cy="12" r="4.706" fill="#fff"></circle>
    </g>
    <defs>
      <filter
        id="filter0_dd_1201_50177"
        width="26"
        height="26"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.06 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1201_50177"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.1 0"></feColorMatrix>
        <feBlend
          in2="effect1_dropShadow_1201_50177"
          result="effect2_dropShadow_1201_50177"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_1201_50177"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

export default InfoCircleIcon;
