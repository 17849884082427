import React, { useEffect } from "react";
import { AppContext } from "../../App";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import BuyCart from "../icons/BuyCart";

const StyledCartButton = styled(Button)`
  height: 44px;
  padding: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d5d7da;
  color: #414651;
  position: relative;

  span {
    background: #df365d;
    color: white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    font-size: 10px;
    padding-top: 1px;
    margin-top: -7px;
    margin-right: -7px;
  }

  &:hover {
    color: #414651;
    background-color: #fff;
    border: 1px solid #d5d7da;
  }

  &:active {
    color: #414651 !important;
    background-color: #fff !important;
    border: 1px solid #d5d7da !important;
  }

  &.btn:focus-visible {
    background-color: #fff;
    color: #414651;
    border: 1px solid #d5d7da;
  }
`;

// Componente de Dropdown personalizado
const CartButton = ({ items, startIcon, endIcon, text, sticky, navbarBackground }) => {
  useEffect(() => {
    const setBadgeItems = () => {
      let cart = JSON.parse(localStorage.getItem("cart"));
      if (!cart) {
        return;
      }
      setCartContext((prevState) => ({
        ...prevState,
        totalItems: cart.data.totalItems,
      }));
    };
    setBadgeItems();
  }, []);

  const { cartContext, setCartContext } = React.useContext(AppContext);

  const handleShowModal = () => {
    setCartContext((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
    }));
  };
  return (
    <StyledCartButton onClick={handleShowModal}>
      <BuyCart size={18}
      fillColor={`${!sticky && navbarBackground == 'navbar-transparent' ? '#fff' : '#414651'}`} />
      {cartContext?.totalItems > 0 && (
        <span id="cartTotalItems">{cartContext?.totalItems}</span>
      )}
    </StyledCartButton>
  );
};

export default CartButton;
