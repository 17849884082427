import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { authenticateUser } from "../../services/axiosService";
import CartService from "../../services/cart.service";
import { associateFavs } from "../../services/favorites.service";
import { decodeJWT } from "../../utilities/jwtDecode";

export default function AuthenticationPage() {
  const { setToken, setIsLogged, setEmail } = React.useContext(AppContext);
  const { setCartContext } = React.useContext(AppContext);
  const { code } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const authenticateUserAsync = async () => {
      try {
        const {
          data: { accessToken },
        } = await authenticateUser(code);
        setToken(accessToken);
        const jwtData = decodeJWT(accessToken);
        setIsLogged(true);
        setEmail(jwtData.email);
        localStorage.setItem("token", accessToken);
        localStorage.setItem("email", jwtData.email);
        associateFavs();
        (new CartService(setCartContext)).associateCart();
        navigate("/");
      } catch (err) {
        console.log("err", err);
        setIsLogged(false);
      }
    };
    authenticateUserAsync();
  }, []);

  return null;
}
