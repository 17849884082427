import React, { useRef, useState } from "react";
import styles from "./HomePageV2.module.scss";
import { getRandomMona } from "../../services/homeMonasService";
import "react-multi-carousel/lib/styles.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import SectionHome2 from "./SectionHome2";
import SectionHome3 from "./SectionHome3";
import SectionHome4 from "./SectionHome4";
import ButtonShop from "./ButtonShop";

gsap.registerPlugin();

const HomePageV2 = () => {

  const [printMona] = useState();
  const mona = getRandomMona();
  const divRef = useRef(null);

  useGSAP(() => {});

  return (
    <div className={styles.Wrapp}>
      <div
        className={styles.ContainerGrid}
        style={{ background: mona.color, height: "100%", zIndex: 1 }}
      >
        <div
          ref={divRef}
          className="home-page fixed"
          style={{
            backgroundImage: `url(${printMona ?? mona.img})`,
          }}
        />
      </div>

      <ButtonShop />
      <SectionHome2 />
      <SectionHome3 />
      <SectionHome4 />
    </div>
  );
};

export default HomePageV2;
