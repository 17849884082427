import React, {useEffect} from "react";
import { AppContext } from "../../App.js";

function FavoritesModal({ setShowFavsModal }) {
    const { setLoginModal } =
    React.useContext(AppContext);

    useEffect(() => {
        const handleKeyUp = (e) => {
            if (e.key === "Escape") {
            setShowFavsModal(false);
            }
        };

        const handleClickOutside = (e) => {
            if (e.target.classList.contains("modal-background")) {
            setShowFavsModal(false);
            }
        };

        document.addEventListener("keyup", handleKeyUp);
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("keyup", handleKeyUp);
            document.removeEventListener("click", handleClickOutside);
        };
    }, [setShowFavsModal]);

    let handleCloseButton = () => {
        setShowFavsModal(false);
    };

    let handleLoginButton = () => {
        setShowFavsModal(false);
        setLoginModal(true);
    }

    let handleNotShowButton = () => {
        setShowFavsModal(false);
        localStorage.setItem("showFavsModal", false);
    }

    return (
    <div id="modal-favs-section" className="modal-background">
      <div className="modal-favs-container">
        <div className="Close-Container">
          <div className="IconClose" onClick={() =>handleCloseButton()}></div>
        </div>
        <h2 className="modal-title">¡Crea tu lista de favoritas!</h2>
        <p className="modal-text">
          Explora la colección y guarda las Fancy Monas que más te gusten.
        </p>
        <div className="favorites-grid">
            <img
                className="w-100"
                src="/images/img_save_favs_modal.png"
                alt="the_artist"
            />
        </div>
        <div className="buttons-container">
          <button className="btn btn-light" onClick={() =>handleCloseButton()}>Crear como invitado</button>
          <button className="btn btn-dark" onClick={() => handleLoginButton()}>Iniciar sesión</button>
        </div>
        <p className="no-show" onClick={() => handleNotShowButton()}>No volver a mostrar</p>
      </div>
    </div>
  );
}

export default FavoritesModal;
