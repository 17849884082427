import React, { useEffect, useState } from "react";

import "./artist.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Carousel } from 'react-bootstrap';
import SectionHome4 from "../home/SectionHome4";

function ArtistV2 () {

  const [activo, setActivo] = useState("");
  const [carouselHeight, setCarouselHeight] = useState("auto");

  const actualizarAlturaCarrusel = () => {
    let userWidth = window.innerWidth;
    let marginTop;
    if (window.innerWidth <= 991) {
      if(window.innerWidth >= 576)
        userWidth = 576;
      if(window.innerWidth >= 768)
        userWidth = 680;

      const newHeight = (userWidth * 405) / 500;
      setCarouselHeight(newHeight);


      marginTop = (userWidth * -70) / 500;
    } else {
      setCarouselHeight("auto");
      marginTop = 0;
    }
    const arrows = document.querySelectorAll(".carousel-control-prev-icon, .carousel-control-next-icon");
    arrows.forEach((arrow) => {
      arrow.style.marginTop = `${marginTop}px`;
    });
  };

  useEffect(() => {
    actualizarAlturaCarrusel();
    window.addEventListener("resize", actualizarAlturaCarrusel);

    return () => {
      window.removeEventListener("resize", actualizarAlturaCarrusel);
    };
  }, []);

  return (
    <div className="artistPage">

      {/** header carousel */}
      <div className="carouselContainer">
        <Carousel controls={true} indicators={false} interval={3000}>
          <Carousel.Item>
            <div
              className="d-flex justify-content-center align-items-center carouselItem"
              style={{ backgroundImage: "url('/artist/slider-imagen-1.png')" }}
            >
              <div className="container">
                <h1>
                  ARTISTA ARGENTINO CON OBRA EN EL MOMA.
                </h1>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="d-flex justify-content-center align-items-center carouselItem"
              style={{ backgroundImage: "url('/artist/slider-imagen-2.png')" }}
            >
              <div className="container">
                <h2>
                  REFERENTE DEL ARTE POP EN LATINOAMÉRICA.
                </h2>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="d-flex justify-content-center align-items-center carouselItem"
              style={{ backgroundImage: "url('/artist/slider-imagen-3.png')" }}
            >
              <div className="container">
                <h2>
                  PINTOR, ESCULTOR, DISEÑADOR, ARQUITECTO Y ESCENÓGRAFO.
                </h2>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

      {/** biography edgardo */}
      <div className="container mt-5 biography">
        <div className="row">
        <div className="col-md-12 col-lg-6 d-flex justify-content-center align-items-center">
            <div>
              <h3>EDGARDO GIMENEZ</h3>
              <p className="subtitle">Santa Fé, 1942</p>
              <p>Artista multidiciplinario que desde los 60’s cautiva al mundo con obras que desafían lo convencional y celebran la vida.</p>
              <button type="button">Biografía</button>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <img src="/artist/Edgardo-Gimenez-1.png" />
          </div>
        </div>
      </div>

      {/** photo wall */}
      <div className="container d-flex photoWall ">
        <div className="d-flex flex-column" style={{ flex: "54%" }}>
          <div className="d-flex">
            <div style={{ flex: "33.3%" }}>
              <img src="/artist/Edgardo-Gimenez-2.png" />
            </div>
            <div style={{ flex: "33.3%" }}>
              <img src="/artist/Edgardo-Gimenez-3.png" />
            </div>
            <div style={{ flex: "33.3%" }}>
              <img src="/artist/Edgardo-Gimenez-4.png" />
            </div>
          </div>
          <div className="d-flex">
            <div style={{ flex: "66.6%" }}>
              <img src="/artist/Edgardo-Gimenez-7.png" />
            </div>
            <div style={{ flex: "33%" }}>
              <img src="/artist/Edgardo-Gimenez-8.png" />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column" style={{ flex: "16%" }}>
          <div>
            <img src="/artist/Edgardo-Gimenez-5.png" />
          </div>
          <div>
            <img src="/artist/Edgardo-Gimenez-9.png"  />
          </div>
        </div>

        <div className="d-flex flex-column" style={{ flex: "28%" }}>
          <div>
            <img src="/artist/Edgardo-Gimenez-6.png" className="w-100" />
          </div>
          <div>
            <img src="/artist/Edgardo-Gimenez-10.png" className="w-100" />
          </div>
        </div>
      </div>

      {/** carousel */}
      <div className="container carouselBlack">
        <SectionHome4 onlyCarousel={true} />
      </div>

      {/** accordion */}
      <div className="container mt-5 accordionSite">
        <div className="accordion" id="miAcordeon">

          {/** pintura */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activo !== "one" ? "collapsed" : ""} btn-pintura`}
                onClick={() => setActivo(activo === "one" ? "" : "one")}
              >
                PINTURA
              </button>
            </h2>
            <div className={`accordion-collapse collapse ${activo === "one" ? "show" : ""}`} id="accordion-one">
              <div>
              <Carousel controls={true} indicators={false} interval={3000} style={{ height: carouselHeight }}>
                  <Carousel.Item>
                    <div className="row carousel-slider">
                      <div className="col-md-12 col-lg-10">
                        <img src="/artist/Pintura-1.png" />
                      </div>
                      <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                        <p>En sus pinturas Giménez suele retratar a figuras icónicas del mundo del arte argentino. En este caso se exhibe el Retrato de Federico Klemm (1971).</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="row carousel-slider">
                      <div className="col-md-12 col-lg-10">
                        <img src="/artist/Pintura-2.png" />
                      </div>
                      <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                        <p>Su obra las escaleras dorada (1997) formó parte de laberintos (2022), una exhibición colectiva en la Fundación Proa.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="row carousel-slider">
                      <div className="col-md-12 col-lg-10">
                        <img src="/artist/Pintura-3.png" />
                      </div>
                      <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                        <p>Varias de sus pinturas formaron parte de Edgardo Giménez. No habrá ninguno igual (2023), su exhibición antológica en el Museo de Arte Latinoamericano de Buenos Aires (MALBA).</p>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>

          {/** grafica */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activo !== "two" ? "collapsed" : ""} btn-grafica`}
                onClick={() => setActivo(activo === "two" ? "" : "two")}
              >
                GRÁFICA
              </button>
            </h2>
            <div className={`accordion-collapse collapse ${activo === "two" ? "show" : ""}`} id="accordion-two">
              <Carousel controls={true} indicators={false} interval={3000} style={{ height: carouselHeight }}>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Grafica-1.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Entre 2000 y 2007, Giménez estuvo a cargo del Área de Comunicación en la Vía Pública de la Secretaría de Cultura del Gobierno de la Ciudad de Buenos Aires.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Grafica-2.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>El afiche que realizó en 1964 para el espectáculo Danza Actual fue expuesto en el Museo de Arte Moderno de Nueva York (MoMA) en la exposición Shaping Modernity: Design 1880-1980, Part 1. (2010.)</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Grafica-3.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Una selección de afiches fue reconocida por la prestigiosa editorial Taschen en su publicación The History of Graphic Design 1960-Today (2018).</p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          {/** arquitectura */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activo !== "three" ? "collapsed" : ""} btn-arquitectura`}
                onClick={() => setActivo(activo === "three" ? "" : "three")}
              >
                ARQUITECTURA
              </button>
            </h2>
            <div className={`accordion-collapse collapse ${activo === "three" ? "show" : ""}`}>
              <Carousel controls={true} indicators={false} interval={3000} style={{ height: carouselHeight }}>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Arquitectura-1.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Su primer desarrollo fue la Casa Azul (1970/72) comisionada por el critico de arte Jorge Romero Brest. La casa se encuentra en city bell, provincia de buenos aires.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Arquitectura-2.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>La Casa Azul, fue seleccionada para la exhibición Transformations in modern architecture (1979) en el Museo de Arte Moderno de Nueva York (MOMA).</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Arquitectura-3.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Su más reciente proyecto es la Casa Neptuna (2023), comisionada por la Fundación Ama Amoedo para su programa de residencias artísticas en José Ignacio, Uruguay.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Arquitectura-4.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Casa Neptuna, fue reconocida por la prestigiosa editorial Taschen en su publicación Homes of our time. Contemporary houses around the world vol.3 (2024).</p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          {/** mobiliario */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activo !== "four" ? "collapsed" : ""} btn-mobiliario`}
                onClick={() => setActivo(activo === "four" ? "" : "four")}
              >
                MOBILIARIO
              </button>
            </h2>
            <div className={`accordion-collapse collapse ${activo === "four" ? "show" : ""}`}>
              <Carousel controls={true} indicators={false} interval={3000} style={{ height: carouselHeight }}>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Mobiliario-1.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Entre 1969 y 1970, Giménez ambientó el departamento del reconocido crítico de arte Jorge Romero Brest. El departamento acabó repleto de muebles y obras realizadas por el artista.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Mobiliario-2.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>En 2015, se realizó Siete Muebles, un mono y dos conejos, exhibición individual de muebles curada por Inés Katzenstein en la Universidad Torcuato Di Tella.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Mobiliario-3.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>El mueble Palomar (1978) formó parte de Del cielo a casa, una exhibición historiografía del diseño argentino en el Museo de Arte Latinoamericano de Buenos Aires (MALBA).</p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          {/** escultura */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activo !== "five" ? "collapsed" : ""} btn-escultura`}
                onClick={() => setActivo(activo === "five" ? "" : "five")}
              >
                ESCULTURA
              </button>
            </h2>
            <div className={`accordion-collapse collapse ${activo === "five" ? "show" : ""}`}>
              <Carousel controls={true} indicators={false} interval={3000} style={{ height: carouselHeight }}>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Escultura-1.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Jorge Romero Brest, critico de arte y Martita Bontempi, posan junto a una escultura de un gato realizada por Giménez.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Escultura-2.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Esculturas de monas como parte de la exhibición Holidays (2018) de Edgardo Giménez en MC Galería de Arte.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Escultura-3.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Es el amor, es el amor lo que hace girar al mundo (2022) escultura en homenaje al escritor Lewis Carroll, parte de la exhibición Edgardo Giménez. No habrá ninguno igual (2023), en el Museo de Arte Latinoamericano de Buenos Aires (MALBA).</p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          {/** escenografia */}
          <div className="accordion-item mb-5">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activo !== "six" ? "collapsed" : ""} btn-escenografia`}
                onClick={() => setActivo(activo === "six" ? "" : "six")}
              >
                ESCENOGRAFÍA
              </button>
            </h2>
            <div className={`accordion-collapse collapse ${activo === "six" ? "show" : ""}`}>
              <Carousel controls={true} indicators={false} interval={3000} style={{ height: carouselHeight }}>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Escenografia-1.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Libertad Leblanc en la escenografía realizada por Giménez para el film Psexoanálisis (1967) de Héctor Olivera.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Escenografia-2.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Giménez realizó la escenografía para el filme Los Neuróticos (1968) de Héctor Olivera. En la fotografía aparecen el artista y el director dentro de una de las piezas.</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Escenografia-3.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Ambas escenografías fueron replicadas para la exhibición antológica Edgardo Giménez. No habrá ninguno igual (2023), en el Museo de Arte Latinoamericano de Buenos Aires (MALBA).</p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <img src="/artist/Escenografia-4.png" />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex align-items-end text">
                      <p>Ambas escenografías fueron replicadas para la exhibición antológica Edgardo Giménez. No habrá ninguno igual (2023), en el Museo de Arte Latinoamericano de Buenos Aires (MALBA).</p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ArtistV2;
