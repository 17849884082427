import React from "react";

function PenIconV2() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6666 2.49993C14.8855 2.28106 15.1453 2.10744 15.4313 1.98899C15.7173 1.87054 16.0238 1.80957 16.3333 1.80957C16.6428 1.80957 16.9493 1.87054 17.2353 1.98899C17.5213 2.10744 17.7811 2.28106 18 2.49993C18.2188 2.7188 18.3924 2.97863 18.5109 3.2646C18.6294 3.55057 18.6903 3.85706 18.6903 4.16659C18.6903 4.47612 18.6294 4.78262 18.5109 5.06859C18.3924 5.35455 18.2188 5.61439 18 5.83326L6.74996 17.0833L2.16663 18.3333L3.41663 13.7499L14.6666 2.49993Z" stroke="#414651" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default PenIconV2;
