import { Button } from "react-bootstrap";
import styled from "styled-components";
import Heart from "../icons/Heart";
import { Link } from "react-router-dom";
import routes from "../../routes/routes";

const StyledLikesBtn = styled(Button)`
  height: 44px;
  padding: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d5d7da;
  color: #414651;

  &:hover {
    color: #414651;
    background-color: #fff;
    border: 1px solid #d5d7da;
  }

  &:active {
    color: #414651 !important;
    background-color: #fff !important;
    border: 1px solid #d5d7da !important;
  }

  &.btn:focus-visible {
    background-color: #fff;
    color: #414651;
    border: 1px solid #d5d7da;
  }
`;

// Componente de Dropdown personalizado
const LikesButton = ({ items, startIcon, endIcon, text, navbarBackground, sticky }) => {
  return (
    <Link to={routes.favorites}>
      <StyledLikesBtn>
        <Heart size={18}
        strokeColor={`${!sticky && navbarBackground == 'navbar-transparent' ? '#fff' : '#414651'}`} />
      </StyledLikesBtn>
    </Link>
  );
};

export default LikesButton;
