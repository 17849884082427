import * as React from "react";

const WhatsAppIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    fill="none"
    viewBox="0 0 19 18"
  >
    <path
      fill="#fff"
      d="M16.227 2.616A8.93 8.93 0 0 0 9.887 0C4.947 0 .927 4.001.924 8.919c0 1.572.412 3.106 1.196 4.459L.85 18l4.75-1.24a9 9 0 0 0 4.283 1.085h.004c4.94 0 8.96-4.001 8.962-8.92a8.84 8.84 0 0 0-2.622-6.31m-1.956 9.49c-.186.521-1.081.996-1.511 1.06a3.1 3.1 0 0 1-1.41-.088c-.326-.102-.743-.24-1.277-.47-2.247-.965-3.714-3.216-3.826-3.365-.112-.148-.914-1.208-.914-2.305s.578-1.636.783-1.86a.83.83 0 0 1 .598-.278c.149 0 .298.001.429.008.137.007.322-.052.504.382.186.447.634 1.543.69 1.655s.094.242.019.39c-.075.15-.112.242-.224.372s-.235.291-.336.39c-.112.112-.229.233-.098.456.13.223.58.953 1.246 1.544.855.759 1.576.994 1.8 1.106.224.111.355.093.486-.056.13-.149.56-.651.709-.874.15-.223.298-.186.504-.111.205.074 1.306.613 1.53.725s.373.167.429.26.056.54-.13 1.06"
    ></path>
  </svg>
);

export default WhatsAppIcon;
