import React, {useState, useEffect} from "react";
import "../../styles/artworkLanding.scss";
import ArtworkLandingParallax from "./ArtworkLandingParallax"
import MediaQuery from "react-responsive";
import { Carousel } from 'react-bootstrap';

import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes";

import mona from "../../assets/artworkLanding/Fancy Monas_2x 3.png"
import picture from "../../assets/artworkLanding/Cuadro_monas copy 10.png"
import { getRandomArtworks } from "../../services/axiosService";

export default function ArtworkLanding() {

  const navigate = useNavigate();
  const [randomArtworks, setRandomArtworks] = useState([]);

  useEffect(() => {
    const getArtworks = async () => {
      if(randomArtworks.length == 0) {
        const artworks = await getRandomArtworks();
        setRandomArtworks(artworks.data);
      }
    }
    getArtworks();
  }, [randomArtworks]);

  return(
    <>
      <div className="headerVideo"></div>
      <ArtworkLandingParallax />


      <div className="algorithm">
        <div className="container d-flex mdFlexColumn" style={{ minHeight: '100vh' }}>
          <div className="col-md-12 col-lg-6 d-flex justify-content-center align-items-center text">
            <div>
              <p className="title">
                Luego, para crear las 1942 combinaciones  utilizó un algoritmo generativo.
              </p>
              <p>
                Giménez entrenó al sistema, estableciendo parámetros estéticos que orientaron la producción de las combinaciones de rasgos.
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 d-flex justify-content-center align-items-center image">
            <img src={mona} />
          </div>
        </div>
      </div>

      <div className="picture d-flex justify-content-center align-items-center">
        <div className="box d-flex flex-column">
          <MediaQuery minWidth={992}>
            <div className="bubble-top-left bubble-container">
              <div className="bubble">
                <b>Fine Art Print</b><br />
                Imagen fotográfica de 68,5 x 68,5 cm impresa en papel Hahnemühle® Photo Rag UltraSmooth® de 300 g/m².
              </div>
              <div className="line"></div>
              <div className="circle"><span></span></div>
            </div>

            <div className="bubble-bottom-left bubble-container">
              <div className="bubble">
                <b>Marco</b><br />
                Marco blanco satinado estilo caja de 72,5 x 72,5, realizado en madera Marupá con vidrio cristal.
              </div>
              <div className="line"></div>
              <div className="circle"><span></span></div>
            </div>

            <div className="bubble-top-right bubble-container">
              <div className="bubble">
                <b>Certificado Tokenizado</b><br />
                Pantalla de 12.3 pulgadas de alta calidad con diseño de marco de Edgardo Giménez.
              </div>
            </div>

            <div className="bubble-bottom-right bubble-container">
              <div className="circle"><span></span></div>
              <div className="line"></div>
              <div className="bubble">
                <b>Tag NFC</b><br />
                Acceso a contenido exclusivo sobre la obra via tecnología contactless.
              </div>
            </div>
            <img src={picture} />
          </MediaQuery>




          <MediaQuery maxWidth={991}>
            <div style={{ width: '100vw', overflow: 'hidden', textAlign: 'center'}}>
              <img src={picture} />
            </div>


            <Carousel controls={true} indicators={false} interval={3000} className="carousel-artPrint d-flex">

              <Carousel.Item>
                <div className="bubble-container one">
                  <div className="bubble">
                    <b>Fine Art Print</b><br />
                    Imagen fotográfica de 68,5 x 68,5 cm impresa en papel Hahnemühle® Photo Rag UltraSmooth® de 300 g/m².
                  </div>
                  <div className="line"></div>
                  <div className="circle"><span></span></div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="bubble-container two">
                  <div className="bubble">
                    <b>Marco</b><br />
                    Marco blanco satinado estilo caja de 72,5 x 72,5, realizado en madera Marupá con vidrio cristal.
                  </div>
                  <div className="line"></div>
                  <div className="circle"><span></span></div>
                </div>
              </Carousel.Item>


              <Carousel.Item>
                <div className="bubble-container three">
                  <div className="bubble">
                    <b>Certificado Tokenizado</b><br />
                    Pantalla de 12.3 pulgadas de alta calidad con diseño de marco de Edgardo Giménez.
                    <div className="line"></div>
                    <div className="circle"><span></span></div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="bubble-container four">
                  <div className="circle"><span></span></div>
                  <div className="line"></div>
                  <div className="bubble">
                    <b>Tag NFC</b><br />
                    Acceso a contenido exclusivo sobre la obra via tecnología contactless.
                  </div>
                </div>
              </Carousel.Item>

            </Carousel>
          </MediaQuery>
        </div>
      </div>


      <div className="more-info mb-5">
        <div className="container">
          <div className="d-flex justify-content-between random-items mdFlexColumn">
            {randomArtworks.map((randomArtowk, index) => (
              <div
                key={index}
                style={{ cursor: "pointer" }}
                className="d-inline-block position-relative"
                onClick={() => navigate(routes.coleccion)}
              >
                {index == 0 && (
                  <div className="message">
                    DESCUBRÍ<br />
                    LAS 1942<br/>
                    OBRAS
                  </div>
                )}
                <img src={randomArtowk.compressedImageUrl} alt={`Artwork ${index}`} />
              </div>
            ))}
          </div>
          <p className="title mt-5">Más info sobre las obras: </p>
          <a className="desk">DESK</a>
          <a className="certificate">CERTIFICADO</a>
          <a className="faqs">FAQS</a>
          <a className="news">NOVEDADES</a>
        </div>
      </div>
    </>
  )
}