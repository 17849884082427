import React, { useRef, useState, useEffect } from "react";
import { findMonasByNumber } from "../../services/axiosService";
import { Link } from "react-router-dom";

export default function Navbar({ sticky = false, navbarBackground = 'white' }) {
  const [mockMonas, setMockMonas] = useState([]);
  const dropdownRef = useRef(null);
  const inputSearchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownElement = dropdownRef.current;
      const inputSearchElement = inputSearchRef.current;

      if (
        dropdownElement &&
        !dropdownElement.contains(event.target) &&
        inputSearchElement !== event.target
      ) {
        dropdownHandle(false);
        inputSearchElement.value = "";
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const dropdownHandle = (visible) => {
    const dropdownElement = dropdownRef.current;
    if (visible) dropdownElement.classList.add("show");
    else {
      const inputSearchElement = inputSearchRef.current;
      inputSearchElement.value = "";
      dropdownElement.classList.remove("show");
    }
  };

  const searchMonas = async (event) => {
    const value = event.target.value;
    const isNumeric = /^\d+$/.test(value);

    if (isNumeric) {
      try {
        const result = await findMonasByNumber(value);
        if (result.data.artworks.length > 0) {
          const newMockMonas = result.data.artworks.map((mona) => ({
            number: mona.number,
            img: mona.compressedImageUrl,
            name: `Fancy Mona #${mona.number}`
          }));
          setMockMonas(newMockMonas);
          dropdownHandle(true);
        } else {
          setMockMonas([]);
          dropdownHandle(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const inputSearchElement = inputSearchRef.current;
      inputSearchElement.value = "";
      setMockMonas([]);
      dropdownHandle(false);
    }
  };

  return (
    <div className="input-search-container">
      <div className="form-group has-feedback form-search">
        <input
          style={
            !sticky && navbarBackground === "navbar-transparent"
              ? { backgroundImage: 'url("/images/icons/search-white.png")' }
              : { backgroundImage: 'url("/images/icons/search-gray.png")' }
          }
          type="search"
          className="form-control"
          placeholder="Busca mona por ID"
          onChange={searchMonas}
          ref={inputSearchRef}
        />
      </div>
      <div className="input-search-dropdown" ref={dropdownRef}>
        <ul>
          {mockMonas.map((mona, index) => (
            <li key={index}>
              <Link
                to={`/items/${mona.number}`}
                onClick={() => dropdownHandle(false)}
              >
                <img src={mona.img} alt={mona.name} /> {mona.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
