import React, { useEffect, useState } from "react";
import LogoFancyV2 from "../../components/icons/LogoFancy.v2";
import styles from "./NavbarV2.module.scss";
import { FaBars } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import MediaQuery from "react-responsive";
import CloseIcon from "../../components/icons/CloseIcon";
import InputSearchMonas from "../../components/shared/InputSearchMonas";
import WhatsAppIcon from "../../components/icons/WhatsappIcon";
import InstagramIcon from "../../components/icons/InstagramIcon";
import TwitterIcon from "../../components/icons/TwitterIcon";
import DropDown from "../../components/shared/Droopdown";
import { AppContext } from "../../App";
import ModalContainer from "../../components/modals/ModalContainer";
import { login } from "../../services/axiosService";
import Login from "../../components/shared/Login";
import LikesButton from "../../components/shared/LikesButton";
import CartButton from "../../components/shared/CartButton";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../routes/routes";

const items = [
  { href: "/favorites", name: "Perfil" },
  { href: "/logout", name: "Cerrar sesión" },
];

const NavbarV2 = ({navbarBackground}) => {

  const navigate = useNavigate();

  const { email, loginModal, setLoginModal, configWeb } =
    React.useContext(AppContext);
  const [sticky, setSticky] = useState(false);
  const isDesktop = 1130;
  const isMobile = 1129;

  const headerStickyStyles = () => {
    setSticky(window.scrollY >= 120);
  };

  useEffect(() => {
    window.addEventListener("scroll", headerStickyStyles);
  }, [sticky]);

  const handleOpenMenu = () => {
    document.querySelector(".menu").classList.add(styles.Open);
  };

  const handleCloseMenu = () => {
    document.querySelector(".menu").classList.remove(styles.Open);
  };

  const handleLogin = (email) => {
    login(email);
  };

  return (
    <>
      {
        <ModalContainer>
          {loginModal ? <Login handleLogin={handleLogin} /> : null}
        </ModalContainer>
      }
      <MediaQuery minWidth={isDesktop}>
        <div className={`${styles.NavbarV2} ${sticky && styles.bg} ${navbarBackground}  ${sticky && 'navbar-white'}`} >
          <div className={styles.Wrapp}>
            <div className={styles.Logo}>
              <Link to={routes.home}>
                <LogoFancyV2 fill={`${!sticky && navbarBackground == 'navbar-transparent' ? '#fff' : '#000'}`} />
              </Link>
            </div>
            <div className={styles.Nav}>
              <ul>
                <li
                  className={`navbar-link ${configWeb.currentPage == 'shop' ? 'active' : ''}`}
                  onClick={() => {
                    navigate(routes.coleccion)
                  }}>Shop
                </li>
                <li
                  className={`navbar-link ${configWeb.currentPage == 'project' ? 'active' : ''}`}
                  onClick={() => {
                    navigate(routes.artworkLanding)
                  }}>Proyecto
                </li>
                <li className="navbar-link">Novedades</li>
                <li
                  className={`navbar-link ${configWeb.currentPage == 'artist' ? 'active' : ''}`}
                  onClick={() => {
                    navigate(routes.the_artist)
                  }}>Artista
                </li>
              </ul>
            </div>

            <div className={styles.Actions}>
              {!email ? (
                <>
                  <InputSearchMonas sticky={sticky} navbarBackground={navbarBackground} />
                  <button
                    onClick={() => setLoginModal(true)}
                    className={styles.BtnLogin}
                  >
                    Login
                  </button>
                  <LikesButton sticky={sticky} navbarBackground={navbarBackground} />
                  <CartButton sticky={sticky} navbarBackground={navbarBackground} />
                </>
              ) : (
                <>
                  <div className="d-flex">
                    <InputSearchMonas sticky={sticky} navbarBackground={navbarBackground} />
                    <DropDown
                      text={email}
                      items={items}
                      endIcon={<IoIosArrowDown />}
                    />
                  </div>

                  <LikesButton sticky={sticky} navbarBackground={navbarBackground} />
                  <CartButton sticky={sticky} navbarBackground={navbarBackground} />
                </>
              )}
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={isMobile}>
        <div className={styles.MobileNavbarWrapp}>
          <div className={`${styles.MobileNavbar} ${sticky && styles.bg} ${navbarBackground}`}>
            <div className={`${styles.MobileNavbarWrapp} d-flex`}>
              <FaBars color={`${!sticky && navbarBackground == 'navbar-transparent' ? 'white' : 'black'}`} style={{marginRight: '10px'}} onClick={handleOpenMenu} />
              <span onClick={() => {handleCloseMenu(); navigate(routes.home)}} className="d-flex align-items-center">
                <LogoFancyV2 width={160} fill={`${!sticky && navbarBackground == 'navbar-transparent' ? '#fff' : '#000'}`} />
              </span>
              <div className="d-flex flex-grow-1 justify-content-end">
                <LikesButton sticky={sticky} navbarBackground={navbarBackground} />
                <CartButton sticky={sticky} navbarBackground={navbarBackground} />
              </div>
            </div>
          </div>
          <div className={`menu ${styles.MobileNavbar__Content}`}>
            <header>
              <div className={styles.MobileNavbar_Header}>
                <div onClick={handleCloseMenu} className={styles.BtnClose}>
                  <CloseIcon fill="none" stroke="#fff" />
                </div>
                <div onClick={() => {handleCloseMenu(); navigate(routes.home)}} className={styles.headerLogo}>
                  <LogoFancyV2 fill={`#fff`} />
                </div>
              </div>
            </header>
            <InputSearchMonas />
            <ul>
              <li onClick={() => {
                handleCloseMenu()
                navigate(routes.coleccion)
              }}>Shop</li>
              <li onClick={() => {
                handleCloseMenu()
                navigate(routes.artworkLanding)
              }}>Proyecto</li>
              <li>Novedades</li>
              <li onClick={() => {
                  handleCloseMenu()
                  navigate(routes.the_artist)
                }}>Artista</li>
              { !email && (
                <li onClick={() => setLoginModal(true)}>Login</li>
              )}
            </ul>
            <div className={styles.MobileSocialLinks}>
              <WhatsAppIcon />
              <InstagramIcon />
              <TwitterIcon />
              <span>hello@fancymonas.io</span>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default NavbarV2;
