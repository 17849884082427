import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import routes from "../../routes/routes.js";
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../App";

/** components */
import ModalCart from "../../components/shared/ModalCart.jsx";

/* services */
import CartService from '../../services/cart.service';

/** images */
import ChevronLeft from "../../components/icons/ChevronLeft.v2";
import PenIconV2 from '../../components/icons/PenIcon.v2.jsx';

const FancyCheckoutV2 = () => {

  /* context */
  const { cartContext, setCartContext } = React.useContext(AppContext);
  /* services */
  const navigate = useNavigate();
  const cartService = new CartService(setCartContext);

  /* refs */
  const personalDataFormRef = useRef(null);
  const billingDataFormRef = useRef(null);

  /* states */
  const [step, setStep] = useState(0); // control form step number
  const [paymentMethod, setPaymentMethod] = useState({}); // save payment method
  const [personalDataErrors, setPersonalDataErrors] = useState({}); // set personal data form errors
  const [billingDataErrors, setBillingDataErrors] = useState({}); // set billing data form errors
  const [personalData, setPersonalData] = useState({  // save personal data
    email: '',
    doesAcceptNotifications: '1',
    name: '',
    surname: '',
    phone: '',
    send_to: 'ar',
    address_street: '',
    address_number: '',
    address_floor: '',
    address_departament: '',
    address_city: '',
    address_province: '',
    address_postalcode: '',
    address_country: '',
    comment: '',
    equalBillingData: '1'
  });
  const [billingData, setBillingData] = useState({ // save billing data
    name: '',
    surname: '',
    document_number: '',
    phone: '',
    address_street: '',
    address_number: '',
    address_floor: '',
    address_departament: '',
    address_city: '',
    address_province: '',
    address_postalcode: '',
    address_country: ''
  });

  const verifyForms = (formName) => {

    let form;
    if(formName == 'personalData') {
      form = personalDataFormRef.current;
    } else {
      form = billingDataFormRef.current;
    }

    const dataErrors = {};
    const isValid = form.checkValidity();
    if (!isValid) {
      const fields = form.querySelectorAll('input, textarea, select');
      fields.forEach((field) => {
        if (!field.validity.valid) {
          dataErrors[field.name] = field.validationMessage;
        }
      });
    }

    if(formName == 'personalData') {
      setPersonalDataErrors(dataErrors);
    } else {
      setBillingDataErrors(dataErrors);
    }

    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBillingData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckStep = async () => {
    switch(step) {
      case 0:
        const verifyPersonalData = verifyForms('personalData');
        if(!verifyPersonalData)
          return;

        if(personalData.equalBillingData == "0") {
          const verifyBillingData = verifyForms('billingData');
          if(!verifyBillingData)
            return;
        }

        window.scrollTo(0, 0);
        setStep(1);

        break;
      case 1:
        if(paymentMethod?.type) {
          window.scrollTo(0, 0);
          setStep(2);
        }
        break;
      case 2:
        const response = await cartService.checkout(personalData, billingData, paymentMethod.type);
        if(!response.status) {
          switch(response.httpCode) {
            case 409:
              alert('Hay obras en tu carrito que se encuentran vendidas.');
              break;
            default:
              alert('Ha ocurrido un error inesperado, por favor inténtalo de nuevo más tarde.');
              break;
          }

          return;
        }

        if(['cash', 'bank'].includes(paymentMethod.type)) {
          navigate('/success-payment')
        } else {
          window.location.href = response.data.urlRedirect
        }
        break;
    }
  }

  useEffect(() => {
    const cartSession = cartService.getCartLocalStorage();
    if(!cartSession || cartSession.data.totalItems == 0) {
      navigate('/collection');
    }
  }, [cartContext])

  return(
    <div className="container checkougPage">
      <div className="row">
        <div className="col-lg-8 mb-lg-5">

          {/* breadcrumbs */}
          <div className="breadcrumbs d-flex align-items-center mt-4">
            <Link className="secondaryFont" to={routes.home}>Home</Link>
            <ChevronLeft color={"#A4A7AE"} />
            <Link className="secondaryFont" to={routes.coleccion}>Shop</Link>
            <ChevronLeft color={"#A4A7AE"} />
            <span className="secondaryFont active fw-bold">Datos</span>
          </div>

          {/** progress bar */}
          <div className="progressBar d-flex justify-content-center mt-5">
            <div className={`${step == 0 ? 'active' : 'completed'} circle`}><span></span></div>
            <div className="line"><span className="active"></span></div>
            <div className={`${step == 0 ? 'next' : step == 1 ? 'active' : 'completed'} circle`}><span></span></div>
            <div className="line"><span className={`${step == 0 ? 'next' : 'active'}`}></span></div>
            <div className={`${step == 2 ? 'active' : ''} circle`}><span></span></div>
          </div>

          { step == 0 && (
            <>
              <form ref={personalDataFormRef} className="step0Form">
                {/** email */}
                <section className="mt-5">
                  <div className="form-head">
                    <p className="title">Completa tu email</p>
                    <p className="description">Añade tu información personal y de contacto</p>
                  </div>
                  <div>
                    <label>Email</label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      required
                      onChange={handleChange}
                      value={personalData.email}
                    />
                    {personalDataErrors.email && <small className="text-danger">{personalDataErrors.email}</small>}
                  </div>

                  <div className="form-check mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={personalData.doesAcceptNotifications == "1"}
                      onChange={(e) => {
                        setPersonalData((prev) => ({
                          ...prev,
                          doesAcceptNotifications: (e.target.checked) ? "1" : "0"
                        }));
                      }}
                    />
                    <label className="form-check-label">
                      Quiero recibir ofertas y novedades por email
                    </label>
                  </div>
                </section>

                {/** send type */}
                <section className="mt-4">
                  <div className="form-head">
                    <p className="title">Tipo de envío</p>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="ar"
                      name="send_to"
                      checked={personalData.send_to == 'ar'}
                      onChange={handleChange}
                    />
                    <label className="form-check-label">
                      Envío en Argentina (El envío es gratuito)
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="ex"
                      name="send_to"
                      checked={personalData.send_to == 'ex'}
                      onChange={handleChange}
                    />
                    <label className="form-check-label">
                      Envío al Exterior (Nos pondremos en contacto contigo para cotizar el envío internacional)
                    </label>
                  </div>
                </section>

                {/** personal data */}
                <section className="mt-4">
                  <div className="form-head">
                    <p className="title">Datos del destinatario</p>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label>Nombre*</label>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={personalData.name}
                      />
                      {personalDataErrors.name && <small className="text-danger">{personalDataErrors.name}</small>}
                    </div>
                    <div className="col-6">
                      <label>Apellido*</label>
                      <input
                        name="surname"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={personalData.surname}
                      />
                      {personalDataErrors.surname && <small className="text-danger">{personalDataErrors.surname}</small>}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                    <label>Teléfono*</label>
                      <input
                        name="phone"
                        type="text"
                        className="form-control"
                        onChange={handleChange} required
                        value={personalData.phone}
                      />
                      {personalDataErrors.phone && <small className="text-danger">{personalDataErrors.phone}</small>}
                    </div>
                  </div>

                  <div className="mt-4">
                    <label>Calle*</label>
                    <input
                      name="address_street"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      required
                      value={personalData.address_street}
                    />
                    {personalDataErrors.address_street && <small className="text-danger">{personalDataErrors.address_street}</small>}
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <label>Número*</label>
                      <input
                        name="address_number"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={personalData.address_number}
                      />
                      {personalDataErrors.address_number && <small className="text-danger">{personalDataErrors.address_number}</small>}
                    </div>
                    <div className="col-6">
                      <label>Piso (Opcional)</label>
                      <input
                        name="address_floor"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        value={personalData.address_floor}
                      />
                      {personalDataErrors.address_floor && <small className="text-danger">{personalDataErrors.address_floor}</small>}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <label>Dpto (Opcional)</label>
                      <input
                        name="address_departament"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        value={personalData.address_departament}
                      />
                      {personalDataErrors.address_departament && <small className="text-danger">{personalDataErrors.address_departament}</small>}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <label>Ciudad*</label>
                      <input
                        name="address_city"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={personalData.address_city}
                      />
                      {personalDataErrors.address_city && <small className="text-danger">{personalDataErrors.address_city}</small>}
                    </div>
                    <div className="col-6">
                      <label>
                        { personalData.send_to == 'ar' ? 'Provincia' : 'Provincia/Región/Estado'}
                      </label>
                      <input
                        name="address_province"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={personalData.address_province}
                      />
                      {personalDataErrors.address_province && <small className="text-danger">{personalDataErrors.address_province}</small>}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <label>Código Postal*</label>
                      <input
                        name="address_postalcode"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={personalData.address_postalcode}
                      />
                      {personalDataErrors.address_postalcode && <small className="text-danger">{personalDataErrors.address_postalcode}</small>}
                    </div>
                    { personalData.send_to == 'ex' && (
                        <div className="col-6">
                        <label>País*</label>
                        <input
                          name="address_country"
                          type="text"
                          className="form-control"
                          required
                          value={personalData.address_country}
                          onChange={handleBillingChange}
                        />
                        {personalData.address_country && <small className="text-danger">{personalData.address_country}</small>}
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <label>Comentario</label>
                    <textarea
                      name="comment"
                      className="form-control"
                      onChange={handleChange}>{personalData.comment}</textarea>
                    {personalDataErrors.comment && <small className="text-danger">{personalDataErrors.comment}</small>}
                  </div>
                </section>

                {/** billing data */}
                <div className="mt-4 step0Form">
                  <div className="form-head">
                    <p className="title">Datos de facturación</p>
                  </div>

                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="equalBillingData"  checked={personalData.equalBillingData == "1"} onChange={(e) => {
                      setPersonalData((prev) => ({
                        ...prev,
                        equalBillingData: (e.target.checked) ? "1" : "0"
                      }));
                    }} />
                    <label className="form-check-label">
                      Mis datos de factura y entrega son los mismos
                    </label>
                  </div>
                </div>
              </form>

              <section>
                { personalData.equalBillingData == "0" && (
                  <form ref={billingDataFormRef}>
                    <div className="row">
                      <div className="col-6">
                        <label>Nombre*</label>
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          required
                          value={billingData.name}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.name && <small className="text-danger">{billingDataErrors.name}</small>}
                      </div>
                      <div className="col-6">
                        <label>Apellido*</label>
                        <input
                          name="surname"
                          type="text"
                          className="form-control"
                          required
                          value={billingData.surname}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.surname && <small className="text-danger">{billingDataErrors.surname}</small>}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-6">
                        <label>Teléfono*</label>
                        <input
                          name="phone"
                          type="text"
                          className="form-control"
                          required
                          value={billingData.phone}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.phone && <small className="text-danger">{billingDataErrors.phone}</small>}
                      </div>
                      <div className="col-6">
                        <label>Número de documento*</label>
                        <input
                          name="document_number"
                          type="text"
                          className="form-control"
                          required
                          value={billingData.document_number}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.document_number && <small className="text-danger">{billingDataErrors.document_number}</small>}
                      </div>
                    </div>
                    <div className="mt-4">
                      <label>Calle*</label>
                      <input
                        name="address_street"
                        type="text"
                        className="form-control"
                        required
                        value={billingData.address_street}
                        onChange={handleBillingChange}
                      />
                      {billingDataErrors.address_street && <small className="text-danger">{billingDataErrors.address_street}</small>}
                    </div>

                    <div className="row mt-4">
                      <div className="col-6">
                        <label>Número*</label>
                        <input
                          name="address_number"
                          type="text"
                          className="form-control"
                          required
                          value={billingData.address_number}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.address_number && <small className="text-danger">{billingDataErrors.address_number}</small>}
                      </div>
                      <div className="col-6">
                        <label>Piso (Opcional)</label>
                        <input
                          name="address_floor"
                          type="text"
                          className="form-control"
                          value={billingData.address_floor}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.address_floor && <small className="text-danger">{billingDataErrors.address_floor}</small>}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-6">
                        <label>Dpto (Opcional)</label>
                        <input
                          name="address_departament"
                          type="text"
                          className="form-control"
                          value={billingData.address_departament}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.address_departament && <small className="text-danger">{billingDataErrors.address_departament}</small>}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-6">
                        <label>Ciudad*</label>
                        <input
                          name="address_city"
                          type="text"
                          className="form-control"
                          required
                          value={billingData.address_city}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.address_city && <small className="text-danger">{billingDataErrors.address_city}</small>}
                      </div>
                      <div className="col-6">
                        <label>{ personalData.send_to == 'ar' ? 'Provincia' : 'Provincia/Región/Estado'}</label>
                        <input
                          name="address_province"
                          type="text"
                          className="form-control"
                          required
                          value={billingData.address_province}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.address_province && <small className="text-danger">{billingDataErrors.address_province}</small>}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-6">
                        <label>Código Postal*</label>
                        <input
                          name="address_postalcode"
                          type="text"
                          className="form-control"
                          required
                          value={billingData.address_postalcode}
                          onChange={handleBillingChange}
                        />
                        {billingDataErrors.address_postalcode && <small className="text-danger">{billingDataErrors.address_postalcode}</small>}
                      </div>
                      { personalData.send_to == 'ex' &&(
                        <div className="col-6">
                          <label>País*</label>
                          <input
                            name="address_country"
                            type="text"
                            className="form-control"
                            required
                            value={billingData.address_country}
                            onChange={handleBillingChange}
                          />
                          {billingDataErrors.address_country && <small className="text-danger">{billingDataErrors.address_country}</small>}
                        </div>
                      )}
                    </div>

                  </form>
                )}
              </section>
            </>
          )}

          { step == 1 && (
            <section className="paymentMethod mt-4">
              <div className="form-head">
                <p className="title mb-0">Método de pago</p>
                <p className="description">Por favor elige un método de pago</p>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  onChange={(e) => {
                    setPaymentMethod({
                      type: 'mercadopago',
                      name: 'Mercadopago'
                    });
                    setCartContext((prev) => ({
                      ...prev,
                      paymentMethod: 'mercadopago'
                    }))
                  }}
                  checked={paymentMethod.type == 'mercadopago'}
                />
                <label className="form-check-label">
                  Pagar con Mercadopago (Argentina)
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  onChange={(e) => {
                    setPaymentMethod({
                      type: 'paypal',
                      name: 'Paypal'
                    });
                    setCartContext((prev) => ({
                      ...prev,
                      paymentMethod: 'paypal'
                    }))
                  }}
                  checked={paymentMethod.type == 'paypal'}
                />
                <label className="form-check-label">
                  Pagar con Paypal (Exterior)
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  onChange={(e) => {
                    setPaymentMethod({
                      type: 'bank',
                      name: 'Transferencia bancaria'
                    });
                    setCartContext((prev) => ({
                      ...prev,
                      paymentMethod: 'bank'
                    }))
                  }}
                  checked={paymentMethod.type == 'bank'}
                />
                <label className="form-check-label">
                  Pagar con transferencia bancaria
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  onChange={(e) => {
                    setPaymentMethod({
                      type: 'cash',
                      name: 'Pago en efectivo'
                    });
                    setCartContext((prev) => ({
                      ...prev,
                      paymentMethod: 'cash'
                    }));
                  }}
                  checked={paymentMethod.type == 'cash'}
                />
                <label className="form-check-label">
                  Pagar en efectivo
                </label>
              </div>
            </section>
          )}

          { step == 2 && (
            <section className="mt-5">
              <div className="summary">
                <p className="title">Resumen de tus datos</p>
                <div className="row">
                  <div className="col-md-12 col-lg-9">
                    <p><b>{personalData.name} {personalData.surname}</b></p>
                    <p>{personalData.email}</p>
                  </div>
                  <div className="col-md-12 col-lg-3 edit-button">
                    <button type="button" onClick={() => {setStep(0)}}>
                      <PenIconV2 /> Editar datos
                    </button>
                  </div>
                </div>
              </div>

              <div className="summary">
                <p className="title">Dirección de envío</p>
                <div className="row">
                  <div className="col-md-12 col-lg-9">
                    <p>
                      <b>{personalData.address_street} {personalData.address_number} {personalData.address_floor && `- ${personalData.address_floor}`} {personalData.address_departament && `- ${personalData.address_departament}`} Cp {personalData.address_postalcode}</b>
                    </p>
                    <p>
                      {personalData.address_city}.
                      {personalData.address_province}.
                    </p>
                    <p>*Los envíos al exterior tienen un costo que queda a cargo del comprador.</p>
                  </div>
                  <div className="col-md-12 col-lg-3 edit-button">
                    <button type="button" onClick={() => {setStep(0)}}>
                      <PenIconV2 /> Editar datos
                    </button>
                  </div>
                </div>
              </div>

              <div className="summary">
                <p className="title">Método de pago</p>
                <div className="row">
                  <div className="col-md-12 col-lg-9">
                    <p>{paymentMethod.name || ''}</p>
                  </div>
                  <div className="col-md-12 col-lg-3 edit-button">
                    <button type="button" onClick={() => {setStep(1)}}>
                      <PenIconV2 /> Editar datos
                    </button>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
        <div className="col-lg-4 position-relative">
          <ModalCart checkoutClassName="fancyCheckout" handleContinue={handleCheckStep} />
        </div>
      </div>

    </div>
    );
}

export default FancyCheckoutV2;