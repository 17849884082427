import React from "react";

function BtnZoom(props) {

  const { color = '#ffffff' } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 2.5H17.5M17.5 2.5V7.5M17.5 2.5L11.6667 8.33333M7.5 17.5H2.5M2.5 17.5V12.5M2.5 17.5L8.33333 11.6667" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default BtnZoom;
