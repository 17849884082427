import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../../components/modals/ModalContainer";
import Whitelist from "../../components/shared/Whitelist";
import ModalZoom from "../../components/shared/ModalZoom";
import { AppContext } from "../../App";
import { Fragment } from "react";
import { getCollection, login } from "../../services/axiosService";
import Login from "../../components/shared/Login";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "./my-collection.scss";

export default function MiColeccionPage() {
  const [collection, setCollection] = useState([]);
  const {
    termsModal,
    zoomModal,
    loginModal,
    successEmail,
    setSuccesEmail,
    setIsLogged,
  } = React.useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getCollectionAsync = async () => {
      try {
        const { data: collection } = await getCollection();
        setCollection(collection);
      } catch (err) {
        // Si no esta autorizado, abrir modal de incio de sesion y eliminar token
        if (err.response.status === 401) {
          setIsModalOpen(true);
          localStorage.removeItem("token");
        }
      }
    };
    getCollectionAsync();
  }, []);

  useEffect(() => {
    if (successEmail) {
      navigate("/");
      setSuccesEmail(false);
    }
  }, [successEmail]);

  const onClickItem = (itemNumber) => {
    navigate(`/items/${itemNumber}`);
  };

  const handleLogin = (email) => {
    login(email);
  };

  return (
    <Fragment>
      {<ModalContainer>{termsModal ? <Whitelist /> : null}</ModalContainer>}
      {<ModalContainer>{zoomModal ? <ModalZoom /> : null}</ModalContainer>}
      {
        <ModalContainer>
          {loginModal ? <Login handleLogin={handleLogin} /> : null}
        </ModalContainer>
      }
      <div className="mt-5 pt-5 my-collection">
        <div style={{ width: "100vw" }}>
          <section id="mi-coleccion">
            <h1 className="mb-2 titulo-mi-coleccion">
              Mi colección <span className="iconos-emoji-textos">🐵</span>{" "}
              <span className="numero-mi-coleccion">({collection.length})</span>
            </h1>
            <Swiper
              pagination={{
                clickable: true,
              }}
              className="mySwiper-collection"
              modules={[Pagination]}
              spaceBetween={34}
              breakpoints={{
                360: {
                  slidesPerView: 1.1,
                  slidesPerGroup: 1.1,
                },
                660: {
                  slidesPerView: 1.5,
                  slidesPerGroup: 1.5,
                },
                760: {
                  slidesPerView: 1.8,
                  slidesPerGroup: 1.8,
                },
                860: {
                  slidesPerView: 2.1,
                  slidesPerGroup: 2.1,
                },
                960: {
                  slidesPerView: 2.1,
                  slidesPerGroup: 2.1,
                },
                1060: {
                  slidesPerView: 2.2,
                  slidesPerGroup: 2.2,
                },
                1160: {
                  slidesPerView: 2.2,
                  slidesPerGroup: 2.2,
                },
                1260: {
                  slidesPerView: 2.6,
                  slidesPerGroup: 2.6,
                },
                1360: {
                  slidesPerView: 3.1,
                  slidesPerGroup: 3.1,
                },
                1460: {
                  slidesPerView: 3.1,
                  slidesPerGroup: 3.1,
                },
                1660: {
                  slidesPerView: 3.3,
                  slidesPerGroup: 3.3,
                },
              }}
            >
              {collection.map(({ compressedImageUrl, number }) => (
                <SwiperSlide key={number}>
                  <div
                    role="button"
                    onClick={() => onClickItem(number)}
                    className="card card-mi-coleccion img-fluid shadow"
                  >
                    <img
                      className="item-image rounded card-img-mi-coleccion img-fluid"
                      src={compressedImageUrl}
                      alt="card"
                    />
                    <div className="card-img-overlay img-fluid">
                      <h5 className="card-title text-start"></h5>
                      <p className="card-text text-end item-text-mi-coleccion">
                        <span className="item-badge text-bg-light px-3 rounded-pill badge">
                          #{number}
                        </span>
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        </div>
      </div>
      {isModalOpen && <Login handleLogin={handleLogin} isClosabel={false} />}
    </Fragment>
  );
}
