function MailIcon({ width = 11 }) {

    const height = (width * 9)/11;

    return (
        <svg width={width} height={height} viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.08348 0.828003C9.88875 0.828003 10.5416 1.48081 10.5416 2.28608V7.14634C10.5416 7.95161 9.88875 8.60441 9.08348 8.60441H2.27912C1.47385 8.60441 0.821045 7.95161 0.821045 7.14634V2.28608C0.821045 1.48081 1.47385 0.828003 2.27912 0.828003H9.08348ZM9.56939 2.44551L6.00123 5.56802C5.83326 5.71499 5.58935 5.72724 5.40872 5.60476L5.36113 5.56802L1.79298 2.44599V7.14635C1.79298 7.41477 2.01058 7.63237 2.27901 7.63237H9.08336C9.35179 7.63237 9.56939 7.41477 9.56939 7.14635V2.44551ZM8.83066 1.80007H2.5308L5.68122 4.55643L8.83066 1.80007Z" fill="white"/>
        </svg>
    );
}

export default MailIcon;