import React from "react";
import { Link } from "react-router-dom";
import styles from "./ButtonShop.module.scss";
import "react-multi-carousel/lib/styles.css";

const ButtonShop = () => {
  return (
    <div className={styles.Container}>
      <Link className={styles.ShopButton} to="/collection">SHOP</Link> 
    </div>
  );
};

export default ButtonShop;
