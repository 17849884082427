import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import CartContextProvider from "./context/Cart";
import { CollectionProvider } from "./context/CollectionContext";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";

<script
  src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
  crossOrigin
></script>;

if (process.env.REACT_APP_ENV === "production") {
  console.log("*** INIT ***");
  ReactGA.initialize("G-HLBYKGGR9E");
}

if (process.env.REACT_APP_ENV === "development") {
  console.log("*** INIT DEV MODE ***");
}

const DETECTION_OPTIONS = {
  order: [
    "querystring",
    "cookie",
    "sessionStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
  ],
};

const languages = ["en", "es"];

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    interpolation: { escapeValue: false },
    fallbackLng: "es",
    whitelist: languages,
    resources: {
      es: {
        global: global_es,
      },
      en: {
        global: global_en,
      },
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CartContextProvider>
      <CollectionProvider>
        <I18nextProvider i18n={i18next}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </I18nextProvider>
      </CollectionProvider>
    </CartContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
