import React from 'react';
import { Carousel } from "react-bootstrap";
import BtnZoom from '../icons/BtnZoom.v2';

const CarouselRefHistorical = ({ images, handleShowModal }) => {

  return (
    <Carousel indicators={true} controls={false}>
      {Object.entries(images)
      .map(([key, value], index) => (
      <Carousel.Item key={key}>
        <div className="position-relative">
          <button onClick={() => handleShowModal(value.image, "16px")} type="button" className="btnSmallSquare btnWhite" style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            zIndex: 9
          }}><BtnZoom color="#000" /></button>
          <img src={value.image} className="imgCarouselHistorical" />
        </div>
      </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselRefHistorical;
