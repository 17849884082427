import React from "react";
import { AppContext } from "../../App.js";

import CloseModal from "../icons/CloseModal";

function ModalZoomV2({ imageUrl, borderRadius = 0 }) {

  const { zoomModal, setZoomModal } = React.useContext(AppContext);
  document.addEventListener("keyup", e => {
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setZoomModal(false);
    }
  });

  document.addEventListener("click", e => {
    if (e.target === document.querySelector(".modal-background")) {
      setZoomModal(false);
    }
  });

  return (
    <div id="modal-term-section" className={zoomModal ? "modal-background" : "d-none"}>
      <div className="container position-relative h-100 d-flex justify-content-center align-items-center">
        <button onClick={() => setZoomModal(false)} type="button" className="btnSmallSquare" style={{
          position: "absolute",
          top: "40px",
          right: "40px",
          zIndex: 9
        }}><CloseModal /></button>
        <div className="card modal-card" style={{width: "850px", maxWidth: "90%", borderRadius: borderRadius}}>
          <img
            className="w-100 item-image modal-card-img"
            src={imageUrl}
            alt="card"
            style={{ borderRadius: borderRadius }}
          />
        </div>
      </div>
    </div>
  );
}

export default ModalZoomV2;
