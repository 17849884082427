import React, { useState, useEffect } from 'react';

const ExpandableText = ({ title, text, id, historicalRefSelected, startExpanded = false, length = 159, callback }) => {
  const [expanded, setExpanded] = useState(startExpanded);

  useEffect(() => {
    if (historicalRefSelected === id) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [historicalRefSelected, id]);

  const handleClick = () => {
    if (!expanded) {
      setExpanded(true);
      callback();
    }
  };

  return (
    <div className={`expandable-container ${expanded ? "expanded" : ""}`}>
      <p className="primaryFont title">{title}</p>
      <p>
        {expanded ? text : (text.length > length ? text.slice(0, length) + '...' : text)}
      </p>
      <div className="d-flex justify-content-end">
        <button
          id={`expandable-${id}`}
          onClick={handleClick}
          className={expanded ? "expanded" : ""}
        >
          {expanded ? "Menos info +" : "Más info +"}
        </button>
      </div>
    </div>
  );
};

export default ExpandableText;
