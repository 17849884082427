import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { logout } from "../../services/axiosService";

export default function LogoutPage() {
  const { setToken, setIsLogged, setEmail } = React.useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUserAsync = async () => {
      try {
        logout();
      } catch (err) {
        console.log("err", err);
      }
      setToken(null);
      setIsLogged(false);
      setEmail(null);
      localStorage.removeItem('token');
      localStorage.removeItem("email");
      localStorage.removeItem("cart");
      localStorage.removeItem("favorites");
      navigate("/collection");
    };
    logoutUserAsync();
  }, []);

  return null;
}
