import * as React from "react";

const InstagramIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
  >
    <path
      fill="#fff"
      d="M12.85 9.52a2.48 2.48 0 1 0 0 4.96 2.48 2.48 0 0 0 0-4.96m9.93-2.45a6.5 6.5 0 0 0-.42-2.26 4 4 0 0 0-2.32-2.32 6.5 6.5 0 0 0-2.26-.42C16.49 2 16.11 2 12.85 2s-3.64 0-4.93.07a6.5 6.5 0 0 0-2.26.42 4 4 0 0 0-2.32 2.32 6.5 6.5 0 0 0-.42 2.26c-.07 1.29-.07 1.67-.07 4.93s0 3.64.07 4.93a6.9 6.9 0 0 0 .42 2.27c.199.526.51 1.004.91 1.4.397.402.879.713 1.41.91a6.5 6.5 0 0 0 2.26.42c1.29.07 1.67.07 4.93.07s3.64 0 4.93-.07a6.5 6.5 0 0 0 2.26-.42 3.9 3.9 0 0 0 1.41-.91c.399-.396.71-.874.91-1.4a6.6 6.6 0 0 0 .42-2.27c.07-1.29.07-1.67.07-4.93s0-3.64-.07-4.93m-2.54 8a5.7 5.7 0 0 1-.39 1.8A3.86 3.86 0 0 1 17.72 19a5.7 5.7 0 0 1-1.81.35H9.79A5.7 5.7 0 0 1 7.98 19a3.5 3.5 0 0 1-1.31-.86 3.5 3.5 0 0 1-.82-1.27 5.5 5.5 0 0 1-.34-1.81V8.94a5.5 5.5 0 0 1 .34-1.81 3.5 3.5 0 0 1 .86-1.31c.36-.36.792-.64 1.27-.82a5.7 5.7 0 0 1 1.81-.35h6.12a5.7 5.7 0 0 1 1.81.35 3.5 3.5 0 0 1 1.31.86c.361.359.641.792.82 1.27a5.7 5.7 0 0 1 .35 1.81V12c0 2.06.07 2.27.04 3.06zm-1.6-7.44a2.38 2.38 0 0 0-1.41-1.41A4 4 0 0 0 15.85 6h-6a4 4 0 0 0-1.38.26 2.38 2.38 0 0 0-1.41 1.36A4.3 4.3 0 0 0 6.85 9v6c.009.471.097.938.26 1.38a2.38 2.38 0 0 0 1.41 1.41 4.3 4.3 0 0 0 1.33.26h6a4 4 0 0 0 1.38-.26 2.38 2.38 0 0 0 1.41-1.41A4 4 0 0 0 18.9 15V9c0-.472-.089-.94-.26-1.38zm-5.79 8.19A3.81 3.81 0 0 1 9.04 12a3.82 3.82 0 1 1 3.81 3.82m4-6.89a.9.9 0 0 1 0-1.79.9.9 0 0 1 0 1.79"
    ></path>
  </svg>
);

export default InstagramIcon;
