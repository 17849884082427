import axios from "axios";

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_DOMAIN}/api`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false
});

let isRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (newToken) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;


    if (error.response?.status === 401 && localStorage.getItem("token")) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/refresh-token`,
            {},
            { withCredentials: true }
          );

          localStorage.setItem("token", data.accessToken);
          isRefreshing = false;
          onTokenRefreshed(data.accessToken);
          
          return apiClient(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          refreshSubscribers = [];
          window.location.href = "/logout";
        }
      }

      return new Promise((resolve) => {
        addRefreshSubscriber((newToken) => {
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          resolve(apiClient(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export default apiClient;
