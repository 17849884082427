import { v4 as uuidv4 } from "uuid";
import apiClient from "./apiClientService";

const SIX_MONTHS_MS = 4320 * 60 * 60 * 1000;
const setExpiryAt = () => Date.now() + SIX_MONTHS_MS;

const getFavoritesLocalStorage = () => {
  const favorites = localStorage.getItem("favorites");
  if (!favorites) return null;

  try {
    const parsedFavorites = JSON.parse(favorites);
    if (parsedFavorites.session.expiryAt < Date.now()) return null;
    return parsedFavorites;
  } catch (error) {
    console.error("Error parsing favorites from localStorage", error);
    return null;
  }
};

const getToken = () => {
  const favorites = getFavoritesLocalStorage();
  return favorites?.session.token || uuidv4();
};

const saveFavoritesToLocalStorage = (data, token) => {
  localStorage.setItem(
    "favorites",
    JSON.stringify({
      data,
      session: { token, expiryAt: setExpiryAt() },
    })
  );
};

const getFavorites = async (limit, offset) => {
  try {
    const favsToken = getToken();
    const response = await apiClient.get("/users/favorites", {
      params: { token: favsToken, limit, offset },
    });

    return response;
  } catch (error) {
    console.error("Error fetching favorites", error);
    return null;
  }
};

const getAllFavorites = async () => {
  try {
    const favsToken = getToken();
    const response = await apiClient.get("/users/favorites", {
      params: { token: favsToken, fetchAll: true },
    });

    if (response.data?.favorites) {
      saveFavoritesToLocalStorage(response.data.favorites, favsToken);
      return response.data.favorites;
    }
    return [];
  } catch (error) {
    console.error("Error fetching all favorites", error);
    return null;
  }
};

const toggleFavorites = async (artworkId) => {
  try {
    const favsToken = getToken();
    await apiClient.post("/users/favorites", { token: favsToken, artworkId });
    return await getAllFavorites();
  } catch (error) {
    console.error("Error toggling favorite", error);
    return null;
  }
};

const associateFavs = async () => {
  try {
    const favsToken = getToken();
    await apiClient.post("/users/favorites/associate", { token: favsToken });
    return await getAllFavorites();
  } catch (error) {
    console.error("Error toggling favorite", error);
    return null;
  }
}

export { getFavorites, getAllFavorites, toggleFavorites, associateFavs };
