import { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CartContext } from "../../context/Cart";
import { useNavigate } from "react-router-dom";
import {
  getHistorySales,
  postSellerStartCreditCard,
} from "../../services/axiosService";
import { comercialCheckoutValidation } from "../../utilities/checkoutValidation";
import { Button } from "../shared/Button";
import { Input, InputErrorMessage, Select } from "../shared/Input";
import { Label } from "../shared/Label";
import { toast, ToastContainer } from "react-toastify";
import ModalTerms from "../modals/ModalTerms";
import CheckoutHeader from "../fancy-checkout/CheckoutHeader";
import User from "../icons/User";
import routes from "../../routes/routes";
import CustomDropdown from "../shared/Dropdown";
import HistoryIcon from "../icons/HistoryIcon";
import ListIcon from "../icons/ListIcon";
import LogOutIcon from "../icons/LogOutIcon";
import HeadingHr from "../shared/HeadingHr";
import ContentTermsConditions from "./ContentTerms&Conditions";
import ArrowTop from "../icons/ArrowTop";
import "../../styles/comercialCheckout.scss";
import ModalHistorySales from "../modals/ModalHistorySales";

const ComercialForm = ({ contextValues }) => {
  const [historySalesShowModal, setHistorySalesShowModal] = useState(false);
  const [historySales, setHistorySales] = useState({});
  const [termsModal, setTermsModal] = useState(false);
  const { user, count, isMultiple, isPrinted, getPaymentMethod, countDisplay } =
    useContext(CartContext);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [initialValues] = useState({
    quantity: contextValues.count,
    includesPrinted: contextValues.isPrinted,
    directSale: true,
    email: "",
    phone: "",
    shippingInfo: {
      name: "",
      //ESTOS CAMPOS SON REQUERIDOS POR LO QUE TENGO QUE ENVIAR ALGO
      country: "1",
      postalCode: "1",
      state: "1",
      neighborhood: "1",
      address: "1",
      addressNumber: "1",
      deptNumber: "1",
      contactPhone: "1",
      multiplePrinted: contextValues.isMultiple,
      quantityDisplays: contextValues.countDisplay,
      additionalIndications: "",
    },
    sellerInfo: {
      paymentMethod: "",
    },
    terms: false,
  });

  useEffect(() => {
    const requestHistorySales = async () => {
      await getHistorySales()
        .then((response) => {
          setHistorySales(response.data);
        })
        .catch((error) => {});
    };
    requestHistorySales();
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      navigate(routes.sellers_login);
    }
  }, []);

  const handleSubmit = async (values) => {
    const notify = (text) => toast.error(text);

    // Clonar los valores del formulario
    const newValues = { ...values };

    // Remover el campo aceptarTerminos antes de enviar la solicitud a la API
    delete newValues.terms;
    delete newValues.lastName;
    newValues.shippingInfo.quantityDisplays = countDisplay;

    const valuesToSend = { ...newValues };
    try {
      const response = await postSellerStartCreditCard(valuesToSend);
      getPaymentMethod(response.data);
      localStorage.setItem("token", response.data.token);
      navigate(
        `${routes.comercial_checkout}/${values.sellerInfo.paymentMethod}`
      );
    } catch (error) {
      console.log(error);
      notify(error.message);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate(routes.sellers_login);
  };

  return (
    <>
      <ModalHistorySales
        modal={historySalesShowModal}
        setModal={setHistorySalesShowModal}
        title={"Historial de ventas"}
        subtitle={"Resumen histórico Fancy Monas vendidas"}
      >
        <div id="table">
          <div class="seller-header">
            <div>Vendedor</div>
          </div>
          <div class="client-header">
            <div>Cliente</div>
          </div>
          {historySales?.sales?.map((item, index) => {
            return (
              <>
                <div class="seller-col">{item.sellerEmail}</div>
                <div class="client-col">
                  <div class="primary-data">
                    <div class="name">{item.name ?? ''}</div>
                    <div class="email">{item.userEmail ?? ''}</div>
                    <div class="phone">{item.phone ?? ''}</div>
                    <div class="artworks">
                      {item.artworks?.map((fancymona, index) => {
                        return (
                          <div class="fancymona">
                            <img
                              src={fancymona.compressedImageUrl}
                              alt="mona"
                            />
                            <span>#{fancymona.number}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div class="secondary-data">

                    { item?.currency?.length > 0 && item?.signal?.length > 0
                      ? <div class="signal">{item.currency ?? ''} {item.signal ?? ''}</div>
                      : <div class="signal">-</div>
                    }
                    
                    <div class="payment_method">Metodo de pago: {item.paymentMethod}</div>

                    { item.additionalIndications.length > 0
                      ? <div class="comment">Comentario: {item.additionalIndications ?? ''}</div>
                      : <div class="comment">-</div>
                    }
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </ModalHistorySales>
      <Formik
        initialValues={initialValues}
        validationSchema={comercialCheckoutValidation}
        enableReinitialize={true}
        onSubmit={(values) => {
          const concatenatedName =
            values.shippingInfo.name + " " + values.lastName;
          values.quantity = count;
          values.includesPrinted = isPrinted;
          values.shippingInfo.multiplePrinted = isMultiple;
          values.phone = values.phone.toString();
          values.shippingInfo.name = concatenatedName;
          if (values.signal) {
            values.signal = Number(values.signal);
          }
          handleSubmit(values);
        }}
      >
        {({ setFieldValue, dirty, handleChange, isValid, errors, touched }) => (
          <div>
            <Button
              className="btn-next-step"
              color="secondary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              Siguiente
            </Button>
            <Form>
              <div className="form">
                <div>
                  <div
                    className="container offcanvas offcanvas-end pb-5"
                    tabIndex={-1}
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                  >
                    <div className="offcanvas-header heading-dropdown pt-3">
                      <HeadingHr color="black" heading={"Datos"} />
                      <CustomDropdown
                        text={user?.email ?? "Opciones"}
                        startIcon={
                          <User width={15} height={15} color={"#89CEF8"} />
                        }
                        endIcon={
                          <ArrowTop width={20} height={20} color={"#000"} />
                        }
                        items={[
                          {
                            name: (
                              <div className="d-flex align-items-center custom-dropdown-gap">
                                <ListIcon
                                  width={15}
                                  height={15}
                                  color={"#000"}
                                />{" "}
                                Cola de revelado
                              </div>
                            ),
                            click: () => navigate(routes.revelated_list),
                          },
                          {
                            name: (
                              <div className="d-flex align-items-center custom-dropdown-gap">
                                <HistoryIcon
                                  width={15}
                                  height={15}
                                  color={"#000"}
                                />{" "}
                                Historial de ventas
                              </div>
                            ),
                            click: () => setHistorySalesShowModal(true),
                          },
                          {
                            name: (
                              <div className="d-flex align-items-center custom-dropdown-gap">
                                <LogOutIcon
                                  width={15}
                                  height={15}
                                  color={"#dc3545"}
                                />{" "}
                                <span className="text-danger">Desconectar</span>
                              </div>
                            ),
                            click: logout,
                          },
                        ]}
                      />
                    </div>
                    <div className="offcanvas-body">
                      <div>
                        <CheckoutHeader
                          title={"Datos del comprador"}
                          subtitle={
                            "Añade tu información personal y de contacto"
                          }
                          isEditable={false}
                        />
                      </div>
                      <hr />
                      <div className="row gx-3 mt-3">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="p-3">
                            <Label
                              htmlFor="shippingInfo.name"
                              className="form-label"
                            >
                              Nombre *
                            </Label>
                            <Input type="text" name="shippingInfo.name" />
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="p-3">
                            <Label htmlFor="lastName" className="form-label">
                              Apellidos
                            </Label>
                            <Input type="text" name="lastName" />
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="p-3">
                            <Label htmlFor="email" className="form-label">
                              Dirección de email *
                            </Label>
                            <Input type="text" name="email" />
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="p-3">
                            <Label htmlFor="phone" className="form-label">
                              Teléfono *
                            </Label>
                            <Input
                              type="number"
                              name="phone"
                              aria-label="phone"
                              aria-describedby="phone"
                            />
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6 col-xl-2">
                          <div className="p-3">
                            <Label htmlFor="currency" className="form-label">
                              Moneda
                            </Label>
                            <Field
                              name="currency"
                              as={Select}
                              /* onChange={(e) => setCurrency(e.target.value)}
                            value={currency} */
                              onChange={handleChange}
                              className="form-select"
                              aria-label="select-currency"
                            >
                              <option value="">Selecciona una moneda</option>
                              <option value="USD">USD</option>
                              <option value="Pesos">Pesos</option>
                              <option value="Crypto">Crypto</option>
                            </Field>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                          <div className="p-3">
                            <Label htmlFor="signal" className="form-label">
                              Ingresa el monto
                            </Label>
                            <Input
                              type="number"
                              name="signal"
                              aria-label="signal"
                              aria-describedby="signal"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="p-3">
                            <Label
                              htmlFor="shippingInfo.additionalIndications"
                              className="form-label"
                            >
                              Comentarios adicionales
                            </Label>
                            <Input
                              type="text"
                              name="shippingInfo.additionalIndications"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-2">
                            <div className="pay-method form-check">
                              <Label htmlFor="form-tc">
                                <Field
                                  id=""
                                  type="radio"
                                  value="tc-en-pasos"
                                  checked={selectedOption === "tc-en-pasos"}
                                  onChange={(e) => {
                                    /* setSelectedOption(e.target.value); */
                                    setSelectedOption(e.target.value);
                                    handleChange(e); // Manejar el cambio de valor
                                    setFieldValue(
                                      "sellerInfo.paymentMethod",
                                      e.target.value
                                    ); // Actualizar paymentMethod en Formik
                                  }}
                                />{" "}
                                TC en pasos
                              </Label>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-2">
                            <div className="pay-method form-check">
                              <Label htmlFor="form-efectivo">
                                <Field
                                  id=""
                                  type="radio"
                                  value="efectivo"
                                  checked={selectedOption === "efectivo"}
                                  onChange={(e) => {
                                    setSelectedOption(e.target.value);
                                    handleChange(e); // Manejar el cambio de valor
                                    setFieldValue(
                                      "sellerInfo.paymentMethod",
                                      e.target.value
                                    ); // Actualizar paymentMethod en Formik
                                  }}
                                />{" "}
                                Efectivo
                              </Label>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-2">
                            <div className="pay-method form-check">
                              <Label>
                                <Field
                                  id=""
                                  type="radio"
                                  value="transferencia"
                                  checked={selectedOption === "transferencia"}
                                  onChange={(e) => {
                                    setSelectedOption(e.target.value);
                                    handleChange(e); // Manejar el cambio de valor
                                    setFieldValue(
                                      "sellerInfo.paymentMethod",
                                      e.target.value
                                    ); // Actualizar paymentMethod en Formik
                                  }}
                                />{" "}
                                Transferencia
                              </Label>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-2">
                            <div className="pay-method form-check">
                              <Label>
                                <Field
                                  id=""
                                  type="radio"
                                  value="crypto"
                                  checked={selectedOption === "crypto"}
                                  onChange={(e) => {
                                    setSelectedOption(e.target.value);
                                    handleChange(e); // Manejar el cambio de valor
                                    setFieldValue(
                                      "sellerInfo.paymentMethod",
                                      e.target.value
                                    ); // Actualizar paymentMethod en Formik
                                  }}
                                />{" "}
                                Crypto USDT
                              </Label>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
                            <div className="pay-method form-check">
                              <Label>
                                <Field
                                  id=""
                                  type="radio"
                                  value="CARD"
                                  checked={selectedOption === "CARD"}
                                  onChange={(e) => {
                                    setSelectedOption(e.target.value);
                                    handleChange(e); // Manejar el cambio de valor
                                    setFieldValue(
                                      "sellerInfo.paymentMethod",
                                      e.target.value
                                    ); // Actualizar paymentMethod en Formik
                                  }}
                                />{" "}
                                PayPal Internacional
                              </Label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="p-3">
                              <Label>
                                <Field name="terms" type="checkbox" /> Acepto
                                los{" "}
                                <strong
                                  onClick={() => setTermsModal(true)}
                                  className="text-decoration-underline text-black"
                                >
                                  términos y condiciones
                                </strong>
                              </Label>
                            </div>
                            <InputErrorMessage>
                              <ErrorMessage name="terms" />
                            </InputErrorMessage>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <Button
                              fullwidth
                              type="submit"
                              disabled={!(isValid && dirty)}
                            >
                              Ir a pagar
                            </Button>
                          </div>
                        </div>
                        <ModalTerms
                          termsModal={termsModal}
                          setTermsModal={setTermsModal}
                          title={"Términos y condiciones de Fancy Monas"}
                          subtitle={
                            "Al finalizar la lectura acepta los términos y condiciones para poder continuar"
                          }
                          isClosable={() => setTermsModal(!termsModal)}
                        >
                          <p>
                            <ContentTermsConditions />
                          </p>
                        </ModalTerms>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              theme="light"
            />
          </div>
        )}
      </Formik>
    </>
  );
};

export default ComercialForm;
