const ArrowCarousel = ({ width = 44, height = 44, color = "#F5F5F5" }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            d="M13 20C12.4477 20 12 20.4477 12 21C12 21.5523 12.4477 22 13 22V20ZM32.2071 21.7071C32.5976 21.3166 32.5976 20.6834 32.2071 20.2929L25.8431 13.9289C25.4526 13.5384 24.8195 13.5384 24.4289 13.9289C24.0384 14.3195 24.0384 14.9526 24.4289 15.3431L30.0858 21L24.4289 26.6569C24.0384 27.0474 24.0384 27.6805 24.4289 28.0711C24.8195 28.4616 25.4526 28.4616 25.8431 28.0711L32.2071 21.7071ZM13 22H31.5V20H13V22Z"
            fill={color}
          />
          <circle cx="22" cy="21" r="19" stroke={color} strokeWidth="2" />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="44"
            height="44"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.039 0 0 0 0 0.049 0 0 0 0 0.07 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" />
          </filter>
        </defs>
      </svg>
    );
  };

  export default ArrowCarousel;
