import React from "react";
import styles from "./FooterV2.module.scss";
import EdgardoGimenez from "../icons/EdgardoGimenezIcon";
import WhatsAppIcon from "../icons/WhatsappIcon";
import InstagramIcon from "../icons/InstagramIcon";
import TwitterIcon from "../icons/TwitterIcon";
import MailIcon from "../icons/MailIcon";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes";

const FooterV2 = () => {

  const navigate = useNavigate();

  return (
    <div className={styles.footerv2Wrapper}>
      <footer className={styles.footerv2}>
        <div className={styles.footerv2List}>
          <ul>
            <li onClick={() => {navigate(routes.home)}}>Home</li>
            <li onClick={() => {navigate(routes.coleccion)}}>Shop</li>
            <li onClick={() => {navigate(routes.artworkLanding)}}>Obra</li>
            <li>Novedades</li>
            <li onClick={() => {navigate(routes.the_artist)}}>Artista</li>
            <li>Certificado</li>
            <li>FAQs</li>
          </ul>
          <span>
            {new Date().getFullYear()} | BAG - Beyond Art Group ® Fancy Monas Términos y condiciones
          </span>
        </div>
        <div className={styles.footerv2Logo}>
          <EdgardoGimenez />
        </div>
        <div className={styles.footerv2SocialLinks}>
          <div className={styles.footerv2Icons}>
            <WhatsAppIcon />
            <InstagramIcon />
            <TwitterIcon />
          </div>
          <span><MailIcon width={15} /> hello@fancymonas.io</span>
        </div>
      </footer>
    </div>
  );
};

export default FooterV2;
