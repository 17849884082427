import React, { useEffect, useRef, useState } from "react";
import { AppContext } from "../../App.js";
import anime from "animejs/lib/anime.es.js";
import styles from "./ModalPoinerHome.module.scss";
import CloseIcon from "../../components/icons/CloseIcon.jsx";

function Modal({ open, onClose, isClosable, data }) {
  const { setLoginModal, setSuccesEmail } = React.useContext(AppContext);
  const [isVisible, setIsVisible] = useState(open);
  console.log(data.image);
  document.addEventListener("keyup", (e) => {
    // if we press the ESC
    if (e.key === "Escape" && document.querySelector(styles.modalBackground)) {
      setLoginModal(false);
    }
  });

  document.addEventListener("click", (e) => {
    if (e.target === document.querySelector(styles.modalBackground)) {
      setLoginModal(false);
    }
  });

  let handleOnClick = () => {
    isClosable();
  };

  const animationRef = useRef(null);

  const animation = () => {
    animationRef.current = anime({
      targets: ".el",
      easing: "easeInOutQuad",
      opacity: "100%",
      direction: "alternate",
      translateY: [100, 0],
      translateX: [0, 0],
      delay: anime.stagger(100),
      loop: false,
    });
  };

  const animateClose = () => {
    anime({
      targets: ".animateClose",
      easing: "easeInOutQuad",
      opacity: "0",
      delay: 200,
      translateY: [0, -100],
      translateX: [0, 0],
      loop: false,
      begin: () => {
        animationRef.current.reverse();
        animationRef.current.play();
      },
      complete: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    anime({
      targets: ".animateOpen",
      easing: "easeInOutQuad",
      delay: 200,
      opacity: ["0%", "100%"],
      translateY: [-100, 0],
      translateX: [0, 0],
      loop: false,
      begin: () => {
        animation();
      },
    });
  }, [open]);

  return isVisible ? (
    <div
      id="modal-term-section"
      className={`${styles.modalBackground} animateClose animateOpen`}
    >
      <div className={styles.modalContainer}>
        <div className={styles.CloseContainer} onClick={animateClose}>
          <CloseIcon />
        </div>

        <div
          className={`${styles.Col1} ${data.id === 2 && styles.Col1Extend} el`}
        >
          <div className={`${styles.modal} el`}>
            <h3>{data?.modal.title}</h3>
            <p>{data?.modal.description}</p>
            <button>Ir al Shop</button>
          </div>
        </div>

        <div
          className={`${styles.modalConector} ${
            data.id === 2 && styles.modalConectorExtend
          } .modalConector`}
        >
          <div className={styles.point}>
            <div className={styles.pointInner} />
          </div>
          {/*  <Connector /> */}
        </div>

        <div className={`${styles.Col2} ${data.id === 2 && styles.Col2Extend}`}>
          <img src={data?.image} alt="image-portait" />
        </div>
      </div>
    </div>
  ) : null;
}

export default Modal;
