import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import routes from "../../routes/routes";
import Badge from "../../components/shared/Badge";
import CartIcon from "../../components/icons/Cart";
import BuyButtonSticky from "../../components/shared/BuyButtonSticky";
import {
  Swiper,
  SwiperSlide,
} from "../../../node_modules/swiper/react/swiper-react.js";
import "../../../node_modules/swiper/swiper.scss";
import "../../../node_modules/swiper/modules/navigation/navigation.scss"; // Navigation module
import "../../../node_modules/swiper/modules/effect-cards/effect-cards.scss"; // Pagination module
import "../../../node_modules/swiper/modules/effect-creative/effect-creative.scss"; // Pagination module
import "../../../node_modules/swiper/modules/autoplay/autoplay.scss"; // Pagination module
import { Pagination, EffectCreative, Autoplay, Navigation } from "swiper";
import { getArchiveDetail } from "../../services/axiosService";

const ArchiveDetail = () => {
  const imageNames = Array.from(
    { length: 3 },
    (_, index) => `mona_${index + 0}.png`
  );
  const { archive } = useParams();

  const [archiveDetail, setArchiveDetail] = useState({});

  useEffect(() => {
    const requestArchiveDetail = async () => {
      await getArchiveDetail(archive)
        .then((response) => {
          setArchiveDetail(response.data);
        })
        .catch((error) => {});
    };
    requestArchiveDetail();
  }, []);

  return (
    <div id="archive-detail">
      <div className="archive-detail-wrapper">
        <div />
        <div className="overflow-hidden">
          <section className="container hero-section">
            <div className="w-100 mb-3">
              <img src="/images/archive/ic24-chevron-right.svg" alt="back" />
              <Link to={routes.archive}>
                <a
                  class="link-offset-2 text-black link-underline link-underline-opacity-0"
                  href="#"
                >
                  Volver
                </a>
              </Link>
            </div>
            <div className="detail-info">
              <img
                src={`/images/archive_order/archivo_0${archive}.png`}
                alt="thumb"
              />
              <div className="detail-info-text">
                <h1 className="detail-info-title">
                  {archiveDetail.title ?? ""}
                </h1>
                <p className="detail-info-description">
                  {archiveDetail.text ?? ""}
                </p>
              </div>
            </div>
          </section>

          <section className="monas-carousel">
            <div className="container">
              <span className="monas-carousel-title">
                Fancy Monas con este rasgo
              </span>
            </div>
            <Swiper
              grabCursor={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              modules={[Navigation, Pagination, EffectCreative, Autoplay]}
              className="mySwiper-items w-100"
              spaceBetween={34}
              breakpoints={{
                360: {
                  slidesPerView: 1.2,
                  slidesPerGroup: 1,
                },
                660: {
                  slidesPerView: 1.2,
                  slidesPerGroup: 1,
                },
                760: {
                  slidesPerView: 1.3,
                  slidesPerGroup: 1,
                },
                860: {
                  slidesPerView: 1.3,
                  slidesPerGroup: 1,
                },
                960: {
                  slidesPerView: 2.2,
                  slidesPerGroup: 1,
                },
                1060: {
                  slidesPerView: 2.4,
                  slidesPGroupew: 2,
                },
              }}
            >
              {archiveDetail.related_fancymonas?.map((image, archive) => (
                <SwiperSlide key={archive}>
                  <img src={`${image?.url}`} alt="mona" />
                  <div className="monas-badge">
                    <Badge text={`#${image?.number}`} dark />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>

          <section className="d-none d-sm-none d-md-none d-lg-block bg-banner w-100 mt-5">
            <div className="container archive-banner">
              <div className="banner-content">
                <h2 className="banner-title">
                  Se parte del universo
                  <br />
                  <span>Fancy Monas</span>
                </h2>
                {/* <Link to={`${routes.fancy_checkout}/inicio`}>
                  <button className="mt-3 btn button-banner">
                    <CartIcon width={20} height={20} className="button-icon" />
                    Comprar
                  </button>
                </Link> */}
              </div>
              <img
                width="80%"
                src="/images/archive-detail/mona-banner.svg"
                alt="banner"
              />
            </div>
          </section>
        </div>
      </div>
      {/* BUTTON STICKY CHECKOUT */}
      {/* <div className="d-lg-none d-xl-none d-xxl-none">
        <BuyButtonSticky
          link={`${routes.fancy_checkout}/inicio`}
          button={"Comprar Fancy Monas"}
          author={"by Edgardo Giménez"}
          icon
        />
      </div> */}
    </div>
  );
};

export default ArchiveDetail;
