import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import MainFooter from "../../components/shared/MainFooter";

/* css */
import "../../styles/successPayment.scss"

/* images */
import CheckOk from "../../components/icons/CheckOk";

const SuccessPayment = () => {

  /* services */
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  /* states */
  const [payCashOrBank, setPayCashOrBank] =  useState(false);

  const handleRedirectButton = () => {
    navigate('/');
  }

  useEffect(() => {
    const payment = queryParams.get('payment');
    if(payment) {
      setPayCashOrBank(true);
    }
  }, []);

  return(
    <>
      <section className="d-flex justify-content-center align-items-center successPayment" style={{ height: "100vh"}}>
        <div className="text-center">
          <CheckOk width={53} height={53} />
          <h1 className="mt-5">¡Has comprado tu Fancy Mona con éxito!</h1>

          { !payCashOrBank && (
            <>
              <p className="mt-4">En 24 horas recibirás un mail con novedades sobre la compra y el envío.</p>
              <button className="mt-4 black" type="button" onClick={handleRedirectButton}>Volver a la home</button>
            </>
          )}

          { payCashOrBank && (
            <>
              <p>Elegiste trasferencia o efectivo, comunicate con nosotros para coordinar el pago.</p>
              <div>
                <button className="mt-4 black" type="button">Comunicate a través de Whatsapp</button>
              </div>
              <button className="mt-4 white" type="button" onClick={handleRedirectButton}>Volver a la home</button>
            </>
          )}
        </div>
      </section>
      <MainFooter />
    </>
  );
}
export default SuccessPayment;