import * as React from "react";

const CloseIcon = ({
  width = 44,
  height = 44,
  stroke = "#0A0A0A",
  fill = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 44 44"
  >
    <rect width="44" height="44" fill={fill} rx="22"></rect>
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeWidth="4"
      d="m15 15 14 14M15 15l14 14M15 29l14-14M15 29l14-14"
    ></path>
  </svg>
);

export default CloseIcon;
