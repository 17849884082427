import React, { useState, useEffect } from "react";
import BtnClose from "../../assets/shared/btn-close.png";
import btnFilterMovil from "../../assets/coleccion/btn-filter-movil.png";
import FilterMenuMovil from "../../pages/coleccion/utilities/FilterMenuMovil.utilities";
import FilterDropdowns from "../../pages/coleccion/utilities/FilterDropdowns.utilities";
import FilterDataCRUD from "../../pages/coleccion/utilities/FilterDataCRUD.utilities";
import FilterCarousel from "../../pages/coleccion/utilities/FilterCarousel.utilities";
import { useCollectionContext } from "../../context/CollectionContext";

export default function Filters() {
    const { checkboxValues, gridSize, setGridSize, setOffsetArtworks } = useCollectionContext();
  //------------------------------------
  // MOSTRAR / OCULTAR FILTROS EN MOVIL
  //------------------------------------
  const { filtersContainerRef, filtersContainerHandle } = FilterMenuMovil();

  //-----------------------------
  // MOSTRAR / OCULTAR DROPDOWNS
  //-----------------------------
  const { filterActive, dropdownRef, filterHandle } = FilterDropdowns();

  const {
    carouselRef,
    isScrollable,
    handlePrevButtonClick,
    handleNextButtonClick
  } = FilterCarousel();

  //------------------------------------------
  // GUARDAR Y ELIMINAR FILTROS SELECCIONADOS
  //------------------------------------------
  const { btnDeleteFilterHandle, btnDeleteAllFiltersHandle, handleCheckboxChange } = FilterDataCRUD();

  const [ btnGrid, setBtnGrid ] = useState('first');
  const [ countFilters, setCountFilters ] = useState(0);

  useEffect(() => {
    let count = 0;
    for(let i in checkboxValues) {
      count += checkboxValues[i].length;
    }
    setCountFilters(count);
  }, [checkboxValues])
  //--------------------------------
  // MOSTRAR FILTROS EN EL CAROUSEL
  //--------------------------------
  const renderGroupsContent = () => {
    const fixCheckboxValues = { ...checkboxValues };
    delete fixCheckboxValues.order;
    delete fixCheckboxValues.status;
    const checkboxGroupValues = Object.values(fixCheckboxValues);

    if (checkboxGroupValues.some((groupValues) => groupValues.length > 0)) {
      return (
        <div className="mt-4 d-flex">
          <button
            className="btn-delete-filters"
            onClick={btnDeleteAllFiltersHandle}
          >
            Borrar todos los filtros
          </button>
          {Object.entries(fixCheckboxValues).map(([group, values]) => (
            Array.isArray(values) &&
            values.map((value) => (
              <div
                key={`${group}-${value}`}
                className="carousel-content d-flex align-items-center"
              >
                <span>{group}:</span> {value}{" "}
                <button
                  className="btn-close"
                  onClick={btnDeleteFilterHandle}
                  data-value={value}
                  data-group={group}
                ></button>
              </div>
            ))
          ))}
        </div>
      );
    }
    return null;
  };

  const handleGridChange = (size) => {
    if (size !== gridSize) {
      setGridSize(size);
      setOffsetArtworks(0);
    }
  };

  return (
    <>
      <section
        className="section-filtres d-flex justify-content-between"
        ref={dropdownRef}
      >
        <div className="filters-container" ref={filtersContainerRef}>
          <div className="d-inline-flex flex-column flex-lg-row">
            <div className="d-lg-none d-flex py-4 px-4">
              <div className="col-3"></div>
              <div className="col-6 text-center">
                <b>Filtros</b>
              </div>
              <div className="col-3 d-flex justify-content-end">
                <button
                  className="btn-close"
                  onClick={filtersContainerHandle}
                >
                  <img src={BtnClose} alt="close" />
                </button>
              </div>
            </div>
            <div className="navbar-filters d-lg-none d-block">
              <button className="black">Aplicar filtros</button>
              <button className="mt-2" onClick={btnDeleteAllFiltersHandle}>
                Eliminar todos
              </button>
            </div>
            <div className="accordion" id="filerBackground">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingBackground">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseBackground"
                    aria-expanded="true"
                    aria-controls="collapseBackground"
                    onClick={() => filterHandle("filterBackground")}
                    className={`accordion-button ${filterActive === "filterBackground" ? "" : "collapsed"
                      }`}
                  >
                    Fondo{" "}
                    <span
                      className={`badge badge-dark ${checkboxValues.Fondo.length > 0 ? "" : "d-none"
                        }`}
                    >
                      {checkboxValues.Fondo.length}
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseBackground"
                  aria-labelledby="headingBackground"
                  data-bs-parent="#filerBackground"
                  className={`accordion-collapse collapse ${filterActive === "filterBackground" ? "show" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-1"
                          value="Amarillo"
                          checked={checkboxValues.Fondo.includes("Amarillo")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-1"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Amarillo
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-2"
                          value="Bermellón"
                          checked={checkboxValues.Fondo.includes("Bermellón")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-2"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Bermellón
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-3"
                          value="Blanco"
                          checked={checkboxValues.Fondo.includes("Blanco")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-3"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Blanco
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-4"
                          value="Celeste"
                          checked={checkboxValues.Fondo.includes("Celeste")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-4"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Celeste
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-5"
                          value="Grafito"
                          checked={checkboxValues.Fondo.includes("Grafito")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-5"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Grafito
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-6"
                          value="Gris"
                          checked={checkboxValues.Fondo.includes("Gris")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-6"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Gris
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-7"
                          value="Lima"
                          checked={checkboxValues.Fondo.includes("Lima")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-7"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Lima
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-8"
                          value="Naranja"
                          checked={checkboxValues.Fondo.includes("Naranja")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-8"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Naranja
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-9"
                          value="Naranja Oscuro"
                          checked={checkboxValues.Fondo.includes(
                            "Naranja Oscuro"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-9"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Naranja Oscuro
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-10"
                          value="Negro"
                          checked={checkboxValues.Fondo.includes("Negro")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-10"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Negro
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-11"
                          value="Rosa"
                          checked={checkboxValues.Fondo.includes("Rosa")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-11"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Rosa
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-12"
                          value="Verde"
                          checked={checkboxValues.Fondo.includes("Verde")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-12"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Verde
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-13"
                          value="Verde Uriburu"
                          checked={checkboxValues.Fondo.includes(
                            "Verde Uriburu"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-13"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Verde Uriburu
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Fondo[]"
                          id="fondo-14"
                          value="Violeta"
                          checked={checkboxValues.Fondo.includes("Violeta")}
                          onChange={(e) => handleCheckboxChange(e, "Fondo")}
                        />{" "}
                        <label
                          htmlFor="fondo-14"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Violeta
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="filterFur">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFilterFur">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFur"
                    aria-expanded="true"
                    aria-controls="collapseFur"
                    onClick={() => filterHandle("filterFur")}
                    className={`accordion-button accordion-button ${filterActive === "filterFur" ? "" : "collapsed"
                      }`}
                  >
                    Pelaje{" "}
                    <span
                      className={`badge badge-dark ${checkboxValues.Pelaje.length > 0 ? "" : "d-none"
                        }`}
                    >
                      {checkboxValues.Pelaje.length}
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseFur"
                  aria-labelledby="headingFilterFur"
                  data-bs-parent="#filterFur"
                  className={`accordion-collapse collapse ${filterActive === "filterFur" ? "show" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Pelaje[]"
                          value="Albino"
                          id="pelaje-1"
                          checked={checkboxValues.Pelaje.includes("Albino")}
                          onChange={(e) => handleCheckboxChange(e, "Pelaje")}
                        />{" "}
                        <label
                          htmlFor="pelaje-1"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Albino
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Pelaje[]"
                          value="Azul"
                          id="pelaje-2"
                          checked={checkboxValues.Pelaje.includes("Azul")}
                          onChange={(e) => handleCheckboxChange(e, "Pelaje")}
                        />{" "}
                        <label
                          htmlFor="pelaje-2"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Azul
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Pelaje[]"
                          value="Castaño oscuro"
                          id="pelaje-3"
                          checked={checkboxValues.Pelaje.includes(
                            "Castaño oscuro"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Pelaje")}
                        />{" "}
                        <label
                          htmlFor="pelaje-3"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Castaño oscuro
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Pelaje[]"
                          value="Colorado"
                          id="pelaje-4"
                          checked={checkboxValues.Pelaje.includes("Colorado")}
                          onChange={(e) => handleCheckboxChange(e, "Pelaje")}
                        />{" "}
                        <label
                          htmlFor="pelaje-4"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Colorado
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Pelaje[]"
                          value="Gris"
                          id="pelaje-5"
                          checked={checkboxValues.Pelaje.includes("Gris")}
                          onChange={(e) => handleCheckboxChange(e, "Pelaje")}
                        />{" "}
                        <label
                          htmlFor="pelaje-5"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Gris
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Pelaje[]"
                          value="Negro"
                          id="pelaje-6"
                          checked={checkboxValues.Pelaje.includes("Negro")}
                          onChange={(e) => handleCheckboxChange(e, "Pelaje")}
                        />{" "}
                        <label
                          htmlFor="pelaje-6"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Negro
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Pelaje[]"
                          value="Rubio"
                          id="pelaje-7"
                          checked={checkboxValues.Pelaje.includes("Rubio")}
                          onChange={(e) => handleCheckboxChange(e, "Pelaje")}
                        />{" "}
                        <label
                          htmlFor="pelaje-7"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Rubio
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Pelaje[]"
                          value="Verde"
                          id="pelaje-8"
                          checked={checkboxValues.Pelaje.includes("Verde")}
                          onChange={(e) => handleCheckboxChange(e, "Pelaje")}
                        />{" "}
                        <label
                          htmlFor="pelaje-8"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Verde
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="filterLook">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFilterLook">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseLook"
                    aria-expanded="true"
                    aria-controls="collapseLook"
                    onClick={() => filterHandle("filterLook")}
                    className={`accordion-button accordion-button ${filterActive === "filterLook" ? "" : "collapsed"}`}
                  >
                    Ojos{" "}
                    <span
                      className={`badge badge-dark ${checkboxValues.Ojos.length > 0 ? "" : "d-none"}`}
                    >
                      {checkboxValues.Ojos.length}
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseLook"
                  aria-labelledby="headingFilterLook"
                  data-bs-parent="#filterLook"
                  className={`accordion-collapse collapse ${filterActive === "filterLook" ? "show" : ""}`}
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Anteojos Courrèges"
                          id="mirada-1"
                          checked={checkboxValues.Ojos.includes(
                            "Anteojos Courrèges"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-1"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Anteojos Courrèges
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Anteojos Dorados"
                          id="mirada-2"
                          checked={checkboxValues.Ojos.includes(
                            "Anteojos Dorados"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-2"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Anteojos Dorados
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Celeste"
                          id="mirada-3"
                          checked={checkboxValues.Ojos.includes("Celeste")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-3"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Celeste
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Celeste Cómplice"
                          id="mirada-4"
                          checked={checkboxValues.Ojos.includes(
                            "Celeste Cómplice"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-4"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Celeste Cómplice
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Cobre"
                          id="mirada-5"
                          checked={checkboxValues.Ojos.includes("Cobre")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-5"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Cobre
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Cobre Cómplice"
                          id="mirada-6"
                          checked={checkboxValues.Ojos.includes(
                            "Cobre Cómplice"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-6"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Cobre Cómplice
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Esmeralda"
                          id="mirada-7"
                          checked={checkboxValues.Ojos.includes("Esmeralda")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-7"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Esmeralda
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Lila"
                          id="mirada-8"
                          checked={checkboxValues.Ojos.includes("Lila")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-8"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Lila
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Lila Cómplice"
                          id="mirada-9"
                          checked={checkboxValues.Ojos.includes(
                            "Lila Cómplice"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-9"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Lila Cómplice
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Miel"
                          id="mirada-10"
                          checked={checkboxValues.Ojos.includes("Miel")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-10"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Miel
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Miel Cómplice"
                          id="mirada-11"
                          checked={checkboxValues.Ojos.includes(
                            "Miel Cómplice"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-11"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Miel Cómplice
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Naranja"
                          id="mirada-12"
                          checked={checkboxValues.Ojos.includes("Naranja")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-12"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Naranja
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Naranja Cómplice"
                          id="mirada-13"
                          checked={checkboxValues.Ojos.includes(
                            "Naranja Cómplice"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-13"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Naranja Cómplice
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Punk"
                          id="mirada-14"
                          checked={checkboxValues.Ojos.includes("Punk")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-14"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Punk
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Rosa"
                          id="mirada-15"
                          checked={checkboxValues.Ojos.includes("Rosa")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-15"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Rosa
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Rosa Cómplice"
                          id="mirada-16"
                          checked={checkboxValues.Ojos.includes(
                            "Rosa Cómplice"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-16"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Rosa Cómplice
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Turquesa"
                          id="mirada-17"
                          checked={checkboxValues.Ojos.includes("Turquesa")}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-17"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Turquesa
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Turquesa Cómplice"
                          id="mirada-18"
                          checked={checkboxValues.Ojos.includes(
                            "Turquesa Cómplice"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-18"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Turquesa Cómplice
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Ojos[]"
                          value="Verde Cómplice"
                          id="mirada-19"
                          checked={checkboxValues.Ojos.includes(
                            "Verde Cómplice"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Ojos")}
                        />{" "}
                        <label
                          htmlFor="mirada-19"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Verde Cómplice
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="filterMouth">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFilterMouth">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseMouth"
                    aria-expanded="true"
                    aria-controls="collapseMouth"
                    onClick={() => filterHandle("filterMouth")}
                    className={`accordion-button accordion-button ${filterActive === "filterMouth" ? "" : "collapsed"
                      }`}
                  >
                    Boca{" "}
                    <span
                      className={`badge badge-dark ${checkboxValues.Boca.length > 0 ? "" : "d-none"
                        }`}
                    >
                      {checkboxValues.Boca.length}
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseMouth"
                  aria-labelledby="headingFilterMouth"
                  data-bs-parent="#filterMouth"
                  className={`accordion-collapse collapse ${filterActive === "filterMouth" ? "show" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Boca[]"
                          value="Beso"
                          id="boca-1"
                          checked={checkboxValues.Boca.includes("Beso")}
                          onChange={(e) => handleCheckboxChange(e, "Boca")}
                        />{" "}
                        <label
                          htmlFor="boca-1"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Beso
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Boca[]"
                          value="Burlona"
                          id="boca-2"
                          checked={checkboxValues.Boca.includes("Burlona")}
                          onChange={(e) => handleCheckboxChange(e, "Boca")}
                        />{" "}
                        <label
                          htmlFor="boca-2"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Burlona
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Boca[]"
                          value="Juguetona"
                          id="boca-3"
                          checked={checkboxValues.Boca.includes("Juguetona")}
                          onChange={(e) => handleCheckboxChange(e, "Boca")}
                        />{" "}
                        <label
                          htmlFor="boca-3"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Juguetona
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Boca[]"
                          value="Risa"
                          id="boca-4"
                          checked={checkboxValues.Boca.includes("Risa")}
                          onChange={(e) => handleCheckboxChange(e, "Boca")}
                        />{" "}
                        <label
                          htmlFor="boca-4"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Risa
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Boca[]"
                          value="Sonrisa"
                          id="boca-5"
                          checked={checkboxValues.Boca.includes("Sonrisa")}
                          onChange={(e) => handleCheckboxChange(e, "Boca")}
                        />{" "}
                        <label
                          htmlFor="boca-5"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Sonrisa
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="filterAccessories">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingAccesories">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseAccessories"
                    aria-expanded="true"
                    aria-controls="collapseAccessories"
                    onClick={() => filterHandle("filterAccessories")}
                    className={`accordion-button accordion-button ${filterActive === "filterAccessories" ? "" : "collapsed"
                      }`}
                  >
                    Aros{" "}
                    <span
                      className={`badge badge-dark ${checkboxValues.Accesorios.length > 0 ? "" : "d-none"
                        }`}
                    >
                      {checkboxValues.Accesorios.length}
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseAccessories"
                  aria-labelledby="headingAccesories"
                  data-bs-parent="#filterAccessories"
                  className={`accordion-collapse collapse ${filterActive === "filterAccessories" ? "show" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Accesorios[]"
                          value="Coco Chanel"
                          id="accesorios-1"
                          checked={checkboxValues.Accesorios.includes(
                            "Coco Chanel"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Accesorios")
                          }
                        />{" "}
                        <label
                          htmlFor="accesorios-1"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Coco Chanel
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Accesorios[]"
                          value="Dorado"
                          id="accesorios-2"
                          checked={checkboxValues.Accesorios.includes(
                            "Dorado"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Accesorios")
                          }
                        />{" "}
                        <label
                          htmlFor="accesorios-2"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Dorado
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Accesorios[]"
                          value="Estrella"
                          id="accesorios-3"
                          checked={checkboxValues.Accesorios.includes(
                            "Estrella"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Accesorios")
                          }
                        />{" "}
                        <label
                          htmlFor="accesorios-3"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Estrella
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Accesorios[]"
                          value="Loro"
                          id="accesorios-4"
                          checked={checkboxValues.Accesorios.includes("Loro")}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Accesorios")
                          }
                        />{" "}
                        <label
                          htmlFor="accesorios-4"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Loro
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Accesorios[]"
                          value="Perla Espiral"
                          id="accesorios-5"
                          checked={checkboxValues.Accesorios.includes(
                            "Perla Espiral"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Accesorios")
                          }
                        />{" "}
                        <label
                          htmlFor="accesorios-5"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Perla Espiral
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Accesorios[]"
                          value="Perla De Oro"
                          id="accesorios-6"
                          checked={checkboxValues.Accesorios.includes(
                            "Perla De Oro"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Accesorios")
                          }
                        />{" "}
                        <label
                          htmlFor="accesorios-6"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Perla De Oro
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Accesorios[]"
                          value="Perla Doble"
                          id="accesorios-7"
                          checked={checkboxValues.Accesorios.includes(
                            "Perla Doble"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Accesorios")
                          }
                        />{" "}
                        <label
                          htmlFor="accesorios-7"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Perla Doble
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="filterSuit">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSuit">
                  <button
                    className={`accordion-button accordion-button ${filterActive === "filterSuit" ? "" : "collapsed"
                      }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSuit"
                    aria-expanded="true"
                    aria-controls="collapseSuit"
                    onClick={() => filterHandle("filterSuit")}
                  >
                    Traje{" "}
                    <span
                      className={`badge badge-dark ${checkboxValues.Traje.length > 0 ? "" : "d-none"
                        }`}
                    >
                      {checkboxValues.Traje.length}
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseSuit"
                  aria-labelledby="headingSuit"
                  data-bs-parent="#filterSuit"
                  className={`accordion-collapse collapse ${filterActive === "filterSuit" ? "show" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Camisa de Estrellas"
                          id="traje-1"
                          checked={checkboxValues.Traje.includes(
                            "Camisa de Estrellas"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-1"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Camisa de Estrellas
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Chaleco Bordado"
                          id="traje-2"
                          checked={checkboxValues.Traje.includes(
                            "Chaleco Bordado"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-2"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Chaleco Bordado
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Chaleco Mariposa"
                          id="traje-3"
                          checked={checkboxValues.Traje.includes(
                            "Chaleco Mariposa"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-3"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Chaleco Mariposa
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Camisa Mosca"
                          id="traje-4"
                          checked={checkboxValues.Traje.includes(
                            "Camisa Mosca"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-4"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Camisa Mosca
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Desnuda"
                          id="traje-5"
                          checked={checkboxValues.Traje.includes("Desnuda")}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-5"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Desnuda
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Remera Arcoiris"
                          id="traje-6"
                          checked={checkboxValues.Traje.includes(
                            "Remera Arcoiris"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-6"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Remera Arcoiris
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Remera Bahia"
                          id="traje-7"
                          checked={checkboxValues.Traje.includes(
                            "Remera Bahia"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-7"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Remera Bahia
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Remera Corazón"
                          id="traje-8"
                          checked={checkboxValues.Traje.includes(
                            "Remera Corazón"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-8"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Remera Corazón
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Remera Mix & Match"
                          id="traje-9"
                          checked={checkboxValues.Traje.includes(
                            "Remera Mix & Match"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-9"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Remera Mix & Match
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Afrika"
                          id="traje-10"
                          checked={checkboxValues.Traje.includes(
                            "Traje Afrika"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-10"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Afrika
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Animal Print"
                          id="traje-11"
                          checked={checkboxValues.Traje.includes(
                            "Traje Animal Print"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-11"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Animal Print
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Corbata"
                          id="traje-12"
                          checked={checkboxValues.Traje.includes(
                            "Traje Corbata"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-12"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Corbata
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Espacial"
                          id="traje-13"
                          checked={checkboxValues.Traje.includes(
                            "Traje Espacial"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-13"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Espacial
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Genial"
                          id="traje-14"
                          checked={checkboxValues.Traje.includes(
                            "Traje Genial"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-14"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Genial
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Gris"
                          id="traje-15"
                          checked={checkboxValues.Traje.includes(
                            "Traje Gris"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-15"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Gris
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Re Mona"
                          id="traje-16"
                          checked={checkboxValues.Traje.includes(
                            "Traje Re Mona"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-16"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Re Mona
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Naval"
                          id="traje-17"
                          checked={checkboxValues.Traje.includes(
                            "Traje Naval"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-17"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Naval
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Platino"
                          id="traje-18"
                          checked={checkboxValues.Traje.includes(
                            "Traje Platino"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-18"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Platino
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Pop"
                          id="traje-19"
                          checked={checkboxValues.Traje.includes("Traje Pop")}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-19"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Pop
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Washington"
                          id="traje-20"
                          checked={checkboxValues.Traje.includes(
                            "Traje Washington"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-20"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Washington
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Traje Watkins"
                          id="traje-21"
                          checked={checkboxValues.Traje.includes(
                            "Traje Watkins"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-21"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Traje Watkins
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="TShirt"
                          id="traje-22"
                          checked={checkboxValues.Traje.includes("TShirt")}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-22"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        T-Shirt
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Vestido Besucona"
                          id="traje-23"
                          checked={checkboxValues.Traje.includes(
                            "Vestido Besucona"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-23"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Vestido Besucona
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Vestido Celia Cruz"
                          id="traje-24"
                          checked={checkboxValues.Traje.includes(
                            "Vestido Celia Cruz"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-24"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Vestido Celia Cruz
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Vestido Lazo"
                          id="traje-25"
                          checked={checkboxValues.Traje.includes(
                            "Vestido Lazo"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-25"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Vestido Lazo
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Vestido Ludovica"
                          id="traje-26"
                          checked={checkboxValues.Traje.includes(
                            "Vestido Ludovica"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-26"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Vestido Ludovica
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Vestido Rumba"
                          id="traje-27"
                          checked={checkboxValues.Traje.includes(
                            "Vestido Rumba"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-27"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Vestido Rumba
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Vestido Pat"
                          id="traje-28"
                          checked={checkboxValues.Traje.includes(
                            "Vestido Pat"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-28"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Vestido Pat
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Traje[]"
                          value="Vestido Vogue Azul"
                          id="traje-29"
                          checked={checkboxValues.Traje.includes(
                            "Vestido Vogue Azul"
                          )}
                          onChange={(e) => handleCheckboxChange(e, "Traje")}
                        />{" "}
                        <label
                          htmlFor="traje-29"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Vestido Vogue Azul
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="filterHat">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingHat">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseHat"
                    aria-expanded="true"
                    aria-controls="collapseHat"
                    onClick={() => filterHandle("filterHat")}
                    className={`accordion-button ${filterActive === "filterHat" ? "" : "collapsed"
                      }`}
                  >
                    Sombrero{" "}
                    <span
                      className={`badge badge-dark ${checkboxValues.Sombrero.length > 0 ? "" : "d-none"
                        }`}
                    >
                      {checkboxValues.Sombrero.length}
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseHat"
                  aria-labelledby="headingHat"
                  data-bs-parent="#filterHat"
                  className={`accordion-collapse collapse ${filterActive === "filterHat" ? "show" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Desnuda"
                          id="sombrero-1"
                          checked={checkboxValues.Sombrero.includes(
                            "Desnuda"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-1"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Desnuda
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Moño Minnie"
                          id="sombrero-2"
                          checked={checkboxValues.Sombrero.includes(
                            "Moño Minnie"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-2"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Moño Minnie
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Peinado Afro"
                          id="sombrero-3"
                          checked={checkboxValues.Sombrero.includes(
                            "Peinado Afro"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-3"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Peinado Afro
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Peinado Futurista"
                          id="sombrero-4"
                          checked={checkboxValues.Sombrero.includes(
                            "Peinado Futurista"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-4"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Peinado Futurista
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Peinado Jopo"
                          id="sombrero-5"
                          checked={checkboxValues.Sombrero.includes(
                            "Peinado Jopo"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-5"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Peinado Jopo
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Peinado Ludovica"
                          id="sombrero-6"
                          checked={checkboxValues.Sombrero.includes(
                            "Peinado Ludovica"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-6"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Peinado Ludovica
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Peinado Picapiedra"
                          id="sombrero-7"
                          checked={checkboxValues.Sombrero.includes(
                            "Peinado Picapiedra"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-7"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Peinado Picapiedra
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Peinado Punk"
                          id="sombrero-8"
                          checked={checkboxValues.Sombrero.includes(
                            "Peinado Punk"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-8"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Peinado Punk
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Peinado Reina"
                          id="sombrero-9"
                          checked={checkboxValues.Sombrero.includes(
                            "Peinado Reina"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-9"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Peinado Reina
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Peinado Watkins"
                          id="sombrero-10"
                          checked={checkboxValues.Sombrero.includes(
                            "Peinado Watkins"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-10"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Peinado Watkins
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Sombrero Arcoiris"
                          id="sombrero-11"
                          checked={checkboxValues.Sombrero.includes(
                            "Sombrero Arcoiris"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-11"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Sombrero Arcoiris
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Sombrero Carmen Miranda"
                          id="sombrero-12"
                          checked={checkboxValues.Sombrero.includes(
                            "Sombrero Carmen Miranda"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-12"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Sombrero Carmen Miranda
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Sombrero Nubes"
                          id="sombrero-13"
                          checked={checkboxValues.Sombrero.includes(
                            "Sombrero Nubes"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-13"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Sombrero Nubes
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Sombrero Pollo"
                          id="sombrero-14"
                          checked={checkboxValues.Sombrero.includes(
                            "Sombrero Pollo"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-14"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Sombrero Pollo
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Sombrero Velo"
                          id="sombrero-15"
                          checked={checkboxValues.Sombrero.includes(
                            "Sombrero Velo"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-15"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Sombrero Velo
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado Afrika"
                          id="sombrero-16"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado Afrika"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-16"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado Afrika
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado De Plumas"
                          id="sombrero-17"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado De Plumas"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-17"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado De Plumas
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado Espacial"
                          id="sombrero-18"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado Espacial"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-18"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado Espacial
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado Felisa Celeste"
                          id="sombrero-19"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado Felisa Celeste"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-19"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado Felisa
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado Helado"
                          id="sombrero-20"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado Helado"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-20"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado Helado
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado Marilú"
                          id="sombrero-21"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado Marilú"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-21"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado Marilú
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado Nina"
                          id="sombrero-22"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado Nina"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-22"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado Nina
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado Re Mona"
                          id="sombrero-23"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado Re Mona"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-23"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado Re Mona
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="Sombrero[]"
                          value="Tocado Serpiente"
                          id="sombrero-24"
                          checked={checkboxValues.Sombrero.includes(
                            "Tocado Serpiente"
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Sombrero")
                          }
                        />{" "}
                        <label
                          htmlFor="sombrero-24"
                          className="custom-checkbox-label"
                        ></label>{" "}
                        Tocado Serpiente
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-none d-inlie-flex btn-filters-container">
          <button onClick={filtersContainerHandle} className="button-select-filters">
            {countFilters > 0 && (
              <span className="lgHide">{countFilters}</span>
            )}
            <img src={btnFilterMovil} alt="filtrar" />
          </button>
        </div>
        <div className="d-inline-flex right-filters-container">
          <div className="grill-buttons">
            <button
              className={`grill-three-btn ${btnGrid == 'first' ? 'active' : ''}`}
              onClick={() => {
                handleGridChange(3);
                setBtnGrid('first');
              }}
            >
            </button>
          </div>
          <div className={`grill-buttons`}>
            <button
              className={`grill-five-btn ${btnGrid == 'second' ? 'active' : ''}`}
              onClick={() => {
                handleGridChange(5);
                setBtnGrid('second');
              }}
            >
            </button>
          </div>
          <div className="order-container">
            <div className="accordion mdHide" id="filterOrder">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOrder">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOrder"
                    aria-expanded="true"
                    aria-controls="collapseOrder"
                    onClick={() => filterHandle("filterOrder")}
                    className={`accordion-button ${filterActive === "filterOrder" ? "" : "collapsed"
                      }`}
                  >
                    Ordenar
                  </button>
                </h2>
                <div
                  id="collapseOrder"
                  aria-labelledby="headingOrder"
                  data-bs-parent="#filterOrder"
                  className={`accordion-movil accordion-collapse collapse ${filterActive === "filterOrder" ? "show" : ""
                    }`}
                >
                  <div className="accordion-body filter-order">
                    <div
                      className={`${filterActive === "filterOrder" ? "" : "hidden"
                        }`}
                    >
                      <div className="d-lg-none d-flex px-4 py-4">
                        <div className="col-3"></div>
                        <div className="col-6 text-center">
                          <b>Filtros</b>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                          <button
                            className="btn-close"
                            onClick={() => filterHandle("filterOrder")}
                          >
                            <img src={BtnClose} alt="close" />
                          </button>
                        </div>
                      </div>
                      <div className="navbar-filters d-lg-none d-block">
                        <button className="black">Aplicar filtros</button>
                        <button
                          className="mt-2"
                          onClick={btnDeleteAllFiltersHandle}
                        >
                          Eliminar todos
                        </button>
                      </div>
                      <ul>
                        <li>
                          <input
                            type="radio"
                            className="custom-radio"
                            name="order"
                            value="ASC"
                            onChange={(e) => handleCheckboxChange(e, "order")}
                            id="order-1"
                          />{" "}
                          <label
                            htmlFor="order-1"
                            className="custom-radio-label"
                          ></label>{" "}
                          Menor a Mayor
                        </li>
                        <li>
                          <input
                            type="radio"
                            className="custom-radio"
                            name="order"
                            value="DESC"
                            onChange={(e) => handleCheckboxChange(e, "order")}
                            id="order-2"
                          />{" "}
                          <label
                            htmlFor="order-2"
                            className="custom-radio-label"
                          ></label>{" "}
                          Mayor a Menor
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-selected-filters d-flex">
        <div className="carousel-container" ref={carouselRef}>
          {renderGroupsContent()}
        </div>
        {isScrollable && (
          <>
            <button
              className="btn-prev"
              onClick={handlePrevButtonClick}
            ></button>
            <button
              className="btn-next"
              onClick={handleNextButtonClick}
            ></button>
          </>
        )}
      </section>
    </>
  );
}
