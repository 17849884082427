function Carousel(data) {
    const carousel = data.carousel;
    const carouselWidth = carousel.offsetWidth;
    const content = carousel.children[0];
    const contentWidth = content.offsetWidth;
    let speed = data.speed || 0.5;
    let animationFrame;

    function cloneContent() {
        if (carouselWidth > contentWidth) {
            const numberOfContents = Math.ceil(carouselWidth / contentWidth);
            carousel.style.width = numberOfContents * contentWidth + "px";
            for (let i = 0; i < numberOfContents; ++i) {
                const clone = content.cloneNode(true);
                clone.dataset.left = (i + 1) * contentWidth;
                clone.style.transform = `translateX(${clone.dataset.left}px)`;
                carousel.appendChild(clone);
            }
        } else {
            const clone = content.cloneNode(true);
            clone.dataset.left = contentWidth;
            clone.style.transform = `translateX(${clone.dataset.left}px)`;
            carousel.appendChild(clone);
        }
    }

    function update() {
        let removeLine = null;
        const children = Array.from(carousel.children);
        for (let i = 0; i < children.length; ++i) {
            const line = children[i];
            let leftValue = parseFloat(line.dataset.left) || 0;
            leftValue -= speed;
            line.dataset.left = leftValue;
            line.style.transform = `translateX(${leftValue}px)`;
            if (leftValue <= -contentWidth) {
                removeLine = line;
            }
        }
        if (removeLine) {
            const lastLine = children[children.length - 1];
            removeLine.dataset.left = parseFloat(lastLine.dataset.left) + contentWidth;
            removeLine.style.transform = `translateX(${removeLine.dataset.left}px)`;
            carousel.appendChild(removeLine);
        }
        animationFrame = requestAnimationFrame(update);
    }

    cloneContent();
    update();
}
export default Carousel;