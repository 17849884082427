import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import routes from "../../routes/routes.js";
import { Carousel } from "react-bootstrap";
import { AppContext } from "../../App";

/* services */
import { getProfile } from "../../services/axiosService";
import historicalRef from "./monas.v2.json"
import CartService from "../../services/cart.service.js";

/* components */
import ExpandableText from "../../components/items/ExpandibleText.component.jsx";
import ModalZoomV2 from "../../components/shared/ModalZoom.v2.jsx";
import CarouselRefHistorical from "../../components/items/CarouselRefHistorical.jsx";

/* images */
import ChevronLeft from "../../components/icons/ChevronLeft.v2.jsx";
import ShopingBag from "../../components/icons/ShopingBag.v2.jsx";
import BtnZoom from "../../components/icons/BtnZoom.v2.jsx";
import ArrowCarousel from "../../components/icons/ArrowCarousel.jsx";

export default function ItemsPage() {

  /* artplacer script widget */
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.artplacer.com/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const navigate = useNavigate();

  /* controlWidth */
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);

  /* modal for zoom images */
  const { setZoomModal, setCartContext } = React.useContext(AppContext);
  const [ modalImage, setModalImage] = useState({
    image: '',
    borderRadius: 0
  });

  /* image, number and metadata item */
  const [{ imageUrl, metadata, artworkId, isReserved, isSold }, setItem] = useState({});
  const { itemNumber } = useParams();

  /* for histrorical references */
  const [historicalReferences, setHistoricalReferences] = useState({
    hat: null,
    eyes: null,
    suit: null
  });
  const [historicalRefSelected, setHistoricalRefSelected] = useState(null);

  useEffect(() => {
    setHistoricalReferences({
      hat: historicalRef.Sombrero[metadata?.Sombrero] ?? null,
      eyes: historicalRef.Ojos[metadata?.Ojos] ?? null,
      suit: historicalRef.Traje[metadata?.Traje] ?? null
    });
    setHistoricalRefSelected(
      (metadata?.Sombrero && 'hat') ||
      (metadata?.Ojos && 'eyes') ||
      (metadata?.Traje && 'suit') ||
      null
    );
  }, [metadata])

  /* get profile */
  useEffect(() => {
    const getProfileAsync = async () => {
      try {
        const formattedNumber = itemNumber.startsWith("0")
          ? itemNumber.slice(1)
          : itemNumber;

        const convertedNumber = Number(formattedNumber);
        if (!convertedNumber) {
          navigate("not-found");
        } else {
          const itemProfile = await getProfile(formattedNumber);
          itemProfile.data.isReserved = true;
          setItem(itemProfile.data);
        }
      } catch (err) {
        console.error("err", err);
      }
    };
    getProfileAsync();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [itemNumber]);

  /* carousel shop */
  const [carouselFmonaActiveIndex, setCarouselFmonaActiveIndex] = useState(0);
  const carouselShopRef = useRef(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    if (metadata && carouselShopRef.current) {
      setTimeout(handleCarouselShopHeight, 400);
    }
  }, [metadata]);

  const handleCarouselShopHeight = () => {
    /* get height of first slider */
    const currentHeight = carouselShopRef.current.offsetHeight;
    carouselShopRef.current.style.overflowY = 'hidden';
    carouselShopRef.current.style.height = `${currentHeight}px`;
    carouselShopRef.current.style.overflowY = 'scroll';

    /* set height to second slider */
    if(carouselShopRef.current.children[1]) {
      const secondSlider = carouselShopRef.current.children[1];
      if(secondSlider) {
        secondSlider.classList.remove('d-none');
        secondSlider.classList.add('d-flex');
        secondSlider.style.height = `${currentHeight}px`;
      }
    }
  }

  const blockScroll = useRef(false);
  const handleCarouselShop = (scrollToTop) => {
    if (carouselShopRef.current) {
      if(blockScroll.current)
        return;

      blockScroll.current = true;
      if (scrollToTop) {
        document.getElementById('carouselDot-1').classList.remove('active');
        document.getElementById('carouselDot-0').classList.add('active');
        carouselShopRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        setCarouselFmonaActiveIndex(0)
      } else {
        document.getElementById('carouselDot-1').classList.add('active');
        document.getElementById('carouselDot-0').classList.remove('active');
        carouselShopRef.current.scrollTo({
          top: carouselShopRef.current.scrollHeight,
          behavior: 'smooth'
        });
        setCarouselFmonaActiveIndex(1)
      }

      setTimeout(() => {
        blockScroll.current = false;
      }, 500);
    }
  };

  const handleCarouselShopScroll = () => {
    if (carouselShopRef.current) {

      const currentScrollTop = carouselShopRef.current.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        handleCarouselShop(false);
      } else if (currentScrollTop < lastScrollTop) {
        handleCarouselShop(true);
      }

      setLastScrollTop(currentScrollTop);
    }
  };

  useEffect(() => {
    const carousel = carouselShopRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', handleCarouselShopScroll);
      return () => {
        carousel.removeEventListener('scroll', handleCarouselShopScroll);
      };
    }
  }, [lastScrollTop]);

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 992);
    handleCarouselShopHeight();
  };

  const handleShowModal = (image, borderRadius) => {
    setModalImage({
      image: image,
      borderRadius: borderRadius
  })
    setZoomModal(true);
  }

  const cartService = new CartService(setCartContext);
  return (
    <div className="w-100 h-100 min-vw-100 min-vh-100 itemPage">
      <ModalZoomV2 imageUrl={modalImage.image} borderRadius={modalImage.borderRadius} />

      <div className="container wrapper-item-page">

        {/* breadcrumbs */}
        <div className="lgFlex align-items-center">
          <ChevronLeft />
          <Link className="linkBread secondaryFont colorAqua fw-bold" to={routes.coleccion}>Volver al shop</Link>
        </div>

          {/* title page */}
          <div>
            <h1 className="primaryFont titulo-item">FANCY MONA #{itemNumber}</h1>
          </div>

          {/* monkey attributes */}
          <div className="d-flex mdFlexColumn">
            <div className="col-lg-8 monkeyContainer d-flex">
              <div className="d-flex justify-content-center align-items-center flex-column w-100 position-relative">

                {!isReserved && !isSold && (
                    <>
                      <button type="button" onClick={() => {handleCarouselShop(false)}} className="btnCarouselShopNext">
                        <ArrowCarousel color={'#D5D7DA'} />
                      </button>
                      <button type="button" onClick={() => {handleCarouselShop(true)}} className="btnCarouselShopPrev">
                        <ArrowCarousel color={'#D5D7DA'} />
                      </button>
                    </>
                  )}
                  <Carousel controls={false} indicators={false} interval={null} activeIndex={carouselFmonaActiveIndex} onSelect={(selectedIndex) => setCarouselFmonaActiveIndex(selectedIndex)}>
                    <Carousel.Item>
                      <div className="text-center">
                        {imageUrl && (
                          <img
                            src={imageUrl}
                            alt="card"
                          />
                        )}
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="text-center">
                        {imageUrl && (
                          <img
                            src="/images/test-widget.v3.png"
                            alt="card"
                          />
                        )}
                      </div>
                    </Carousel.Item>
                  </Carousel>

                <div className="w-100 mt-4 btnsMonkeyActions d-flex justify-content-center align-items-center gap-2 mdJustifyContentCenter">
                  <div>
                    <button onClick={() => handleShowModal(imageUrl, 0)} type="button" className="btnSmallSquare"><BtnZoom /></button>
                  </div>

                  {/** widget */}
                  <artplacer
                    gallery="107182"
                    resizable="true"
                    frames="false"
                    rotate="false"
                    dimensions_standard="hxw"
                    unit="cm"
                    catalog="false"
                    data_capture_form="false"
                    type="1"
                    lang="es"
                    text="Probar en tu espacio"
                    className="btnArtplacer"
                    artwork_url="//fancymonas.front.test.oaro.work/images/test-widget.v3.png"
                  ></artplacer>
                  <artplacer
                    gallery="107182"
                    resizable="true"
                    frames="false"
                    rotate="false"
                    dimensions_standard="hxw"
                    unit="cm"
                    catalog="false"
                    data_capture_form="false"
                    type="2"
                    lang="es"
                    space="156945"
                    additional_spaces="73591,122633,73739,51548,122423,156959,156945"
                    text="Ver en un espacio"
                    className="btnArtplacer"
                    artwork_url="//fancymonas.front.test.oaro.work/images/test-widget.v3.png"
                  ></artplacer>
                  <artplacer
                    gallery="107182"
                    unit="cm"
                    type="3"
                    data_capture_form="false"
                    className="btnArtplacer"
                    text="Realidad aumentada"
                    artwork_url="//fancymonas.front.test.oaro.work/images/test-widget.v3.png"
                    ></artplacer>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mdMT-3 position-relative">

              {isReserved && (
                <div className="pill reserved">
                  <span></span> Reservada
                </div>
              )}
              {isSold && (
                <div className="pill sold">
                  <span></span> Vendida
                </div>
              )}

              <div ref={carouselShopRef} className="carouselShop">
                <div className="descriptionBox pb-4">

                  {/* box price */}
                  <div className={`box ${isSold || isReserved ? 'box-sold' : ''}`}>
                    <p className="monkeyPrice">
                      <span className="priceText primaryFont">Precio</span><br/>
                      <span className="d-block priceNumber primaryFont">{isSold ? '-' : '2500 USD'}</span>
                    </p>
                  </div>

                  {metadata && (
                    <div className="box">
                      <p className="monkeyCharacter primaryFont">Características</p>
                      <div className="d-flex flex-wrap">
                        {Object.keys(metadata).map((key, index) => (
                          <div key={index} className="monkeyAttributes secondaryFont textGray">
                            {key}: {metadata[key]}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="box">
                    <p className="primaryFont">Certificado</p>
                    <p className="secondaryFont textGray mb-0">Todas las Fancy Monas están respaldadas por un certificado tokenizado que valida la autenticidad y propiedad de la obra. <Link className="showMore">Ver más</Link></p>
                  </div>
                  { !isReserved && !isSold && (
                    <button type="button" className="shop" onClick={() => handleCarouselShop(false)}><ShopingBag /> Agregar al carrito</button>
                  )}
                </div>
                { !isReserved && !isSold && (
                  <div className="addDisplay descriptionBox d-none flex-column h-100">
                    <div style={{ flex: '50%'}}>
                      <p className="title primaryFont">Fine Art Print</p>
                      <p className="subtitle secondaryFont">Sumá un display a tu compra y exhibí tu Fancy Mona de la manera más innovadora.</p>
                      <div className="box">
                        <div className="d-flex">
                        <div style={{ width: '70%'}}>
                            <p className="titleInBox secondaryFont">Fine Art Print</p>
                          </div>
                          <div className="d-flex justify-content-end align-items-end" style={{ width: '30%' }}>
                            <p className="badgeInBox">Incluido</p>
                          </div>
                        </div>
                        <p className="descriptionInBox secondaryFont">Imagen fotográfica de 68x68cm impresa en papel Hahnemühle Photo Rag 300 g/m²</p>
                        <p className="descriptionInBox secondaryFont">*Enmarcado incluído solo para envíos dentro de Argentina.</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-end noBorder" style={{ flex: '50%'}}>
                      <button type="button" className="shop" onClick={async () => {
                      if(artworkId)
                        await cartService.addItemToCart(artworkId)}}><ShopingBag /> Finalizar compra</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          { !isReserved && !isSold && (
            <>
              {/** dots carousel */}
              <div className="col-xs-12 d-flex justify-content-center align-items-center mt-4">
                {[0, 1].map((i) => (
                  <span
                    key={i}
                    id={`carouselDot-${i}`}
                    onClick={() => {handleCarouselShop(i === 0 ? true: false)}}
                    className={`monkeyCarouselDots ${i === 0 ? 'active' : ''}`}
                  ></span>
                ))}
              </div>
            </>
          )}

          {/* carousel */}
          <div className="mt-5">
            <Carousel indicators={true} interval={null}>
              <Carousel.Item>
                <div
                  style={{
                    height: "660px",
                    backgroundColor: "#295461",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    borderRadius: "15px"
                  }}
                >
                  Slide 1
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  style={{
                    height: "660px",
                    backgroundColor: "#295461",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    borderRadius: "15px"
                  }}
                >
                  Slide 2
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  style={{
                    height: "660px",
                    backgroundColor: "#295461",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    borderRadius: "15px"
                  }}
                >
                  Slide 3
                </div>
              </Carousel.Item>
            </Carousel>
          </div>

          {/* historical */}
          <div className="monkeyHistory mt-5 mb-3">
            <h2 className="primaryFont">Referencias históricas</h2>
            <p className="secondaryFont subtitle">Descubre las referencias detrás de los rasgos que conforman tu Fancy Mona</p>
            <div className="row">
              <div className="col-lg-6 historyText">
              {Object.entries(historicalReferences)
                .filter(([_, value]) => value !== null) // not null
                .map(([key, value]) => (
                  <ExpandableText
                    key={key}
                    id={key}
                    title={value.name}
                    text={value.text}
                    startExpanded={historicalRefSelected === key}
                    historicalRefSelected={historicalRefSelected}
                    callback={() => {
                      if(key !== historicalRefSelected) {
                        setHistoricalRefSelected(key);
                      }
                    }}
                  />
                ))}
              </div>
              <div className="col-lg-6 hisotryImages">
                {historicalReferences?.[historicalRefSelected]?.images &&(
                  <CarouselRefHistorical images={
                    isDesktop ?
                    historicalReferences[historicalRefSelected].images.desktop :
                    historicalReferences[historicalRefSelected].images.mobile
                  } handleShowModal={handleShowModal} />
                )}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
