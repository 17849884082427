import apiClient from "./apiClientService";

export const findMonasByNumber = async (number) => {
  const response = await apiClient.get(`/artworks/find-by-number/${number}`);
  return response;
};

export const sendTransferBank = (
  quantity,
  includesPrinted,
  email,
  phone,
  shippingInfo,
  checkbox
) => {
  var data = JSON.stringify({
    quantity: quantity,
    includesPrinted: includesPrinted,
    email: email,
    phone: phone,
    doesAcceptNotifications: checkbox,
    shippingInfo: shippingInfo,
  });

  const response = apiClient.post('/users/start-bank-account-buy', data);
  return response;
};

export const getAvailableArtworks = (token) => {
  const response = apiClient.get('/users/check-available-artworks');
  return response;
};

export const getRandomArtworks = async () => {
  const response = await apiClient.get("/artworks", {
    params: { quantity: 5 },
  });
  return response;
};

export const getArtworksWithFilters = async (
  checkboxValues,
  offsetArtworks,
  limit = 3
) => {
  const params = { ...checkboxValues, offsetArtworks, limit };
  const response = await apiClient.get('/artworks/find-with-filters', { params });
  return response;
};

export const sendCardPayment = (
  quantity,
  includesPrinted,
  email,
  phone,
  shippingInfo,
  checkbox
) => {
  var data = JSON.stringify({
    quantity: quantity,
    includesPrinted: includesPrinted,
    email: email,
    phone: phone,
    doesAcceptNotifications: checkbox,
    shippingInfo: shippingInfo,
  });

  const response = apiClient.post('/users/start-credit-card-buy', data);
  return response;
};

export const checkCode = (code) => {
  var data = JSON.stringify({
    code: code,
  });

  const response = apiClient.post('/artworks/check-code', data);
  return response;
};

export const sendCodes = (codes, email, phone, shippingInfo, checkbox) => {
  var data = JSON.stringify({
    codes: codes,
    email: email,
    phone: phone,
    doesAcceptNotifications: checkbox,
    shippingInfo: shippingInfo,
  });

  const response = apiClient.post('/users/process-code-buy', data);
  return response;
};

export const requestNft = (token) => {
  const response = apiClient.post('/artworks/revealed-artworks');
  return response;
};
export const requestNftMona = (token) => {
  const response = apiClient.post('/artworks/revealed-artworks');
  return response;
};

export const checkArtwork = (quantity) => {
  var data = JSON.stringify({
    intentedQuantity: quantity,
  });

  const response = apiClient.post('/artworks/check-artworks-stock', data);
  return response;
};

export const getProfile = async (itemNumber) => {
  const response = await apiClient.get(`/artworks/${itemNumber}`);
  return response;
};

export const getExclusiveContent = async (itemNumber, type) => {
  const response = await apiClient.get(`/artworks/${itemNumber}/content/${type}`, {responseType: "blob"});

  // TODO: Move this to the page
  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", `fancymona-${itemNumber}-${type}.png`);
  fileLink.setAttribute("target", "_blank");
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
};

export const suscribeToWhilest = async (email) => {
  const data = JSON.stringify({
    email,
  });

  const response = apiClient.post('/users/whitelist', data);
  return response;
};

export const getCollection = async () => {
  const response = await apiClient.get('/users/collection');
  return response;
};

export const authenticateUser = async (code) => {
  const response = await apiClient.post('/users/authenticate', { code }, { withCredentials: true });
  return response;
};

export const login = async (email) => {
  const response = await apiClient.post('/users/login', { email });
  return response;
};

export const logout = async () => {
  const response = await apiClient.post('/users/logout', {}, { withCredentials: true });
  return response;
};

export const sellersLogin = (data) => {
  const response = apiClient.post('/sellers/login', data);
  return response;
};

export const getRefHistoricas = (itemImage) => {
  const data = {
    imageUrl:
      "https://artbag-monas.s3.amazonaws.com/d8a27e31-d3d3-4487-b85b-88179c8b4686.png",
    metadata: [
      {
        key: "Fondo",
        value: "Azul",
      },
      {
        key: "Pelaje",
        value: "Azul",
      },
      {
        key: "Ojos",
        value: "Azul",
      },
      {
        key: "Boca",
        value: "Laugh",
      },
      {
        key: "Accesorios",
        value: "Magic",
      },
      {
        key: "Traje",
        value: "Panda",
      },
      {
        key: "Sombrero",
        value: "Afrika",
      },
    ],
  };

  return data;
};

export const postStartCreditCard = async (data) => {
  const response = apiClient.post('/users/start-credit-card-buy', data);
  return response;
};

export const postProcessCreditCard = async (data) => {
  const response = apiClient.post('/users/process-credit-card-buy', data);
  return response;
};

export const postSellerStartCreditCard = async (data) => {
  const response = apiClient.post('/sellers/start-credit-card-buy', data);
  return response;
};

export const postSellerProcessCreditCard = async (data) => {
  const response = apiClient.post('/sellers/process-credit-card-buy', data);
  return response;
};

export const postSendReveal = async (data) => {
  const response = apiClient.post('/sellers/send-reveal', data);
  return response;
};

export const getListToRevelated = async () => {
  const response = apiClient.get('/sellers/list-to-revelated');
  return response;
};

export const getRevealArtwork = async () => {
  const response = apiClient.get('/sellers/reveal-artwork');
  return response;
};

export const postMonitorFinalized = async () => {
  const data = { finalized: true };
  const response = apiClient.post('/sellers/monitor-finalized', data);
  return response;
};

export const getArchiveDetail = (id) => {
  const response = apiClient.get(`/artworks/get-archive-detail/${id}`);
  return response;
};

export const getHistorySales = () => {
  const response = apiClient.get('/sellers/sales');
  return response;
};
