import React, { useRef } from "react";
import styles from "./SectionHome2.module.scss";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes";

const SectionHome2 = () => {
  const divRef = useRef(null);
  const mosca = useRef(null);
  const navigate = useNavigate();

  return (
    <div id="smooth-wrapper" className={styles.Container}>
      <div id="smooth-content" className={styles.WrappClaimSpan} ref={divRef}>
        <div className={styles.Emojis}>
          <ScrollParallax strength={0.5} lerpEase={0.5}>
            <MouseParallax strength={0.1} lerpEase={0.1} enableOnTouchDevice>
              <span className={`${styles.emoji} ${styles.EmojiParallax1}`}>
                🙈
              </span>
            </MouseParallax>
          </ScrollParallax>
          <ScrollParallax strength={0.4} lerpEase={0.3}>
            <MouseParallax strength={0.1} lerpEase={0.6} enableOnTouchDevice>
              <span className={`${styles.emoji} ${styles.EmojiParallax2}`}>
                💗
              </span>
            </MouseParallax>
          </ScrollParallax>
          <ScrollParallax strength={1} lerpEase={0.5}>
            <MouseParallax strength={0.1} lerpEase={0.4} enableOnTouchDevice>
              <span
                id="emoji"
                ref={mosca}
                className={`${styles.emoji} ${styles.EmojiParallax3} el`}
              >
                🪰
              </span>
            </MouseParallax>
          </ScrollParallax>
          <ScrollParallax strength={0.3} lerpEase={0.1}>
            <MouseParallax strength={0.1} lerpEase={0.3} enableOnTouchDevice>
              <span className={`${styles.emoji} ${styles.EmojiParallax4}`}>
                🍌
              </span>
            </MouseParallax>
          </ScrollParallax>

          <ScrollParallax strength={0.3} lerpEase={0.1}>
            <MouseParallax strength={0.1} lerpEase={0.3} enableOnTouchDevice>
              <span className={`${styles.emoji} ${styles.EmojiParallax5}`}>
                🌈
              </span>
            </MouseParallax>
          </ScrollParallax>

          <div className={styles.WrappText}>
            <ScrollParallax strength={0} lerpEase={0}>
              <div className={styles.ClaimSpan}>
                <span
                  id="item"
                  className={`${styles.HoverLink1} box`}
                  onClick={() => navigate(routes.coleccion)}
                >
                  1942 obras únicas,
                </span>
                <span
                  className={styles.HoverLink2}
                  onClick={() => navigate(routes.coleccion)}
                >
                  creadas por
                </span>{" "}
                <span
                  className={styles.HoverLink3}
                  onClick={() => navigate(routes.archive)}
                >
                  edgardo giménez
                </span>
              </div>
            </ScrollParallax>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHome2;
