import React from "react";

const BuyCart = ({ size = 20, fillColor = "#414651" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.99996L3.5 1.66663H13.5L16 4.99996M1 4.99996V16.6666C1 17.1087 1.17559 17.5326 1.48816 17.8451C1.80072 18.1577 2.22464 18.3333 2.66667 18.3333H14.3333C14.7754 18.3333 15.1993 18.1577 15.5118 17.8451C15.8244 17.5326 16 17.1087 16 16.6666V4.99996M1 4.99996H16M11.8333 8.33329C11.8333 9.21735 11.4821 10.0652 10.857 10.6903C10.2319 11.3154 9.38405 11.6666 8.5 11.6666C7.61594 11.6666 6.7681 11.3154 6.14298 10.6903C5.51786 10.0652 5.16667 9.21735 5.16667 8.33329"
      stroke={fillColor}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BuyCart;