import React from "react";

function CheckOk({ width = 20, height = 20 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.0006 0.333496C12.2807 0.333496 0.333984 12.2802 0.333984 27.0002C0.333984 41.7202 12.2807 53.6668 27.0006 53.6668C41.7206 53.6668 53.6673 41.7202 53.6673 27.0002C53.6673 12.2802 41.7206 0.333496 27.0006 0.333496ZM19.774 38.4402L10.2007 28.8668C9.16065 27.8268 9.16065 26.1468 10.2007 25.1068C11.2407 24.0668 12.9207 24.0668 13.9607 25.1068L21.6673 32.7868L40.014 14.4402C41.054 13.4002 42.734 13.4002 43.774 14.4402C44.814 15.4802 44.814 17.1602 43.774 18.2002L23.534 38.4402C22.5206 39.4802 20.814 39.4802 19.774 38.4402Z" fill="#73D13D"/>
    </svg>
  );
}

export default CheckOk;
