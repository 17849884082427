import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Heart from "../icons/Heart";
import BuyCart from "../icons/BuyCart";
import routes from "../../routes/routes";
import CartService from "../../services/cart.service.js";
import { AppContext } from "../../App";
import { getAllFavorites, toggleFavorites } from "../../services/favorites.service.js";
import ModalContainer from "../modals/ModalContainer.jsx";
import FavoritesModal from "./FavoritesModal.jsx";
import { toast, ToastContainer } from "react-toastify";
import CheckRoundedIcon from "../icons/CheckRoundedIcon.jsx";
import CloseIconV2 from "../icons/CloseIcon.v2.jsx";

const ArtworksGrid = ({ artworks, gridSize, marginLess = false, setFavsDeleted = null }) => {
  const [favorites, setFavorites] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [showFavsModal, setShowFavsModal] = useState(false);

  const { setCartContext, cartContext } = React.useContext(AppContext);
  const cartService = useMemo(() => new CartService(setCartContext), [setCartContext]);

  useEffect(() => {
    const cartSession = cartService.getCartLocalStorage();
    if (cartSession?.data?.cart) {
      const artworkIds = cartSession.data.cart.map(item => item.artwork.id);
      setCartItems(artworkIds);
    }
  }, [cartContext.totalItems, cartService]);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const storedFavorites = await getAllFavorites();
        setFavorites(storedFavorites);
      } catch (error) {
        console.error("Error cargando favoritos:", error);
        setFavorites([]);;
      }
    };

    fetchFavorites();
  }, []);

  const toggleFavorite = async (artworkId, isFavorite) => {
    const storedFavorites = await toggleFavorites(artworkId);
    setFavorites(storedFavorites);
    const mustShowModal = localStorage.getItem("showFavsModal") !== "false";
    if(!isFavorite){
      toastFavorites();
    }else{
      if(setFavsDeleted){
        setFavsDeleted(artworkId);
      }
    }
    if(mustShowModal && !isFavorite){
      setShowFavsModal(true);
    }
  };

  const handleAddToCart = async (artworkId) => {
    try {
      const response = await cartService.addItemToCart(artworkId);
      if (response?.data?.cart) {
        toastAddCart();
        const artworkIds = response.data.cart.map(item => item.artwork.id);
        setCartItems(artworkIds);
      }
    } catch (error) {
      console.error("Error al agregar al carrito:", error);
    }
  };

  const toastFavorites = () => {
    toast.success(
      <div className="custom-toast">
        <div className="toast-info">
          <CheckRoundedIcon />
          <span className="toast-text">¡Guardaste una Fancy Mona en favoritas!</span>
        </div>
        <Link to={routes.favorites}><button className="view-favorites">VER FAVORITAS</button></Link>
      </div>,
      {
        className: "custom-toast-container",
        closeButton: <CloseIconV2 color="white" className="close-icon" />,
        icon: false,
        autoClose: 3000,
      }
    );
  };

  const toastAddCart = () => {
    toast.success(
      <div className="custom-toast">
        <div className="toast-info">
          <CheckRoundedIcon />
          <span className="toast-text">¡Agregaste a tu carrito una Fancy Mona!</span>
        </div>
        <button className="view-favorites" onClick={handleShowCart}>VER CARRITO</button>
      </div>,
      {
        className: "custom-toast-container",
        closeButton: <CloseIconV2 color="white" className="close-icon" />,
        icon: false,
        autoClose: 3000,
      }
    );
  }

  const handleShowCart = () => {
    setCartContext((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
    }));
  };

  return (
    <>
    <section className={`artworks-container container-${gridSize} justify-content-around w-100 ${marginLess ? "artworks-container-marginless" : ""}`}>
      {Array.isArray(artworks) &&
      artworks.map((artwork, index) => {
        const isAvailable = !artwork.isSold && !artwork.isReserved;
        const isFavorite = favorites && favorites.includes(artwork.id);
        const isInCart = cartItems.includes(artwork.id);

        return (
          <div className={`mona col-10-10 col-lg-10-${gridSize}`} key={index}>
            <div className="mona-wrapper">
              <img
                className="mona-image"
                src={artwork.compressedImageUrl}
                alt={`Artwork ${artwork.number}`}
              />
              <div className="mona-overlay position-absolute top-0 start-0 d-flex flex-column">
                <div className="fav-container overlay-section">
                  <div className="fav-container">
                    {isAvailable && (
                      <button
                        className="add-fav border-0 bg-transparent"
                        onClick={() => toggleFavorite(artwork.id, isFavorite)}
                      >
                        <Heart fillColor="none" strokeColor="white" size={30} />
                      </button>
                    )}
                  </div>
                </div>
                <div className="cart-container overlay-section">
                  {isAvailable && !isInCart && (
                    <button
                      className="btn-add-cart d-flex align-items-center gap-2"
                      onClick={() => handleAddToCart(artwork.id)}
                    >
                      <BuyCart size={20} fillColor="#333" /> Agregar al carrito
                    </button>
                  )}
                </div>
                <div className="profile-container overlay-section">
                  <Link to={`${routes.items}/${artwork.number}`} className="text-white fw-bold mt-2 mb-2">
                    Ver perfil
                  </Link>
                </div>
              </div>
              <div className="fav-number-container">
                <div className="fav-container">
                  {isAvailable && (
                    <button className="add-fav border-0 bg-transparent">
                      <Heart
                        fillColor={isFavorite ? "white" : "none"}
                        strokeColor={isFavorite ? "white" : "none"}
                        size={30}
                      />
                    </button>
                  )}
                </div>
                <div className="number-container">
                  <span className="number">
                    {artwork.isSold === 1 || artwork.isReserved === 1 ? (
                      <span className={`status-dot ${artwork.isSold === 1 ? "red" : "orange"}`}></span>
                    ) : null}
                    # {artwork.number}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
    <ModalContainer>
      {showFavsModal ? <FavoritesModal setShowFavsModal={setShowFavsModal} /> : null}
    </ModalContainer>
    <ToastContainer
      position="top-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      theme="light"
    />
    </>
  );
};

export default ArtworksGrid;
