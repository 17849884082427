import React, { useState } from "react";
import routes from "../../routes/routes";
import { Link } from "react-router-dom";
import "../../styles/footer.scss";
import ModalTerms from "../modals/ModalTerms";
import ContentTermsConditions from "../comercial-checkout/ContentTerms&Conditions";
import { AppContext } from "../../App";

const MainFooter = () => {
  const [termsModal, setTermsModal] = useState(false);
  const { setLoginModal } = React.useContext(AppContext);

  return (
    <footer id="main-footer" className="bg-black">
      <div className="main-footer">
        <div className="container main-footer-wrap">
          <div className="main-footer-cols">
            <div className="footer-col-1">
              <img src="/images/byAuthor-white.svg" alt="author" />
              <a
                className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                href="mailto:hello@fancymonas.io"
              >
                hello@fancymonas.io
              </a>
            </div>
            <div className="footer-col-2">
              <span className="footer-sections-title">Secciones</span>
              <ul>
                <Link to={routes.home}>
                  <li>Fancy Monas</li>
                </Link>
                <Link to={routes.archive}>
                  <li>Archivo histórico</li>
                </Link>
                <Link to={routes.algoritmo}>
                  <li>Desarrollo Algorítmico</li>
                </Link>
                <Link to={routes.the_artist}>
                  <li>Sobre el Artista</li>
                </Link>
                <li onClick={() => setLoginModal(true)}>Iniciar Sesión</li>
              </ul>
            </div>
            <div className="footer-col-3">
              <span className="footer-sections-title">Nuestras redes</span>
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/fancymonas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/FancyMonas"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="artbag-column text-uppercase">
            <small className="text-white">
              2022 | <span className="text-lowercase">artbag®</span>
            </small>
            <small
              style={{ cursor: "pointer" }}
              onClick={() => setTermsModal(true)}
              className="text-white text-uppercase"
            >
              Fancy monas términos y condiciones
            </small>
          </div>
        </div>
      </div>
      <ModalTerms
        termsModal={termsModal}
        setTermsModal={setTermsModal}
        title={"Términos y condiciones de Fancy Monas"}
        subtitle={
          "Al finalizar la lectura acepta los términos y condiciones para poder continuar"
        }
        isClosable={() => setTermsModal(!termsModal)}
      >
        <ContentTermsConditions />
      </ModalTerms>
    </footer>
  );
};

export default MainFooter;
