import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import routes from "./routes/routes";
import AuthenticationPage from "./pages/authentication/AuthenticationPage";
import CollectionPage from "./pages/coleccion/ColeccionPage";
import NotFoundPage from "./pages/404/NotFoundPage";
import RedeemPage from "./pages/redeem/RedeemPage";
import ItemsPage from "./pages/items/ItemsPage";
import MiColeccionPage from "./pages/MyCollection/MyCollectionPage";
import ArtistV2 from "./pages/TheArtist/Artist.v2";
import Archive from "./pages/archive/Archive";
import ArchiveDetail from "./pages/archive/ArchiveDetail";
import MainLayout from "./templates/MainLayout";
import FancyCheckoutV2 from "./pages/fancyCheckout/FancyCheckout.v2";
import ArtworkLanding from "./pages/artworkLanding/artworkLanding";
import ThanksPage from "./pages/ThanksPage/ThanksPage";
import ComercialCheckout from "./pages/ComercialCheckout/ComercialCheckout";
import SellersLogin from "./pages/sellers-login/sellersLogin";
import PaymentMethod from "./pages/ComercialCheckout/PaymentMethod";
import RevelatedQueue from "./pages/ComercialCheckout/RevelatedQueue";
import RemoteReveal from "./pages/ComercialCheckout/RemoteReveal";
import PaypalSuccess from "./pages/ThanksPage/PaypalSuccess";
import PaypalError from "./pages/ThanksPage/PaypalError";
import ScrollToTop from "./utilities/ScrollToTop";
import ThanksRedeemPage from "./pages/redeem/ThanksRedeemPage";
import SuccessPayment from "./pages/ThanksPage/SuccessPayment.v2";
import Algoritmo from "./pages/algoritmo/Algoritmo";
import LogoutPage from "./pages/authentication/LogoutPage";

import "../src/styles/whitelist-modal.scss";
import "../src/styles/home.scss";
import "../src/styles/algoritmo.scss";
import "../src/styles/coleccion.scss";
import "../src/styles/favorites.scss";
import "../src/styles/checkout.scss";
import "../src/styles/navbar.scss";
import "../src/styles/redeem.scss";
import "../src/styles/landing.scss";
import "../src/styles/items.scss";
import "../src/styles/modal.scss";
import "../src/styles/rrss.scss";
import "../src/styles/TheArtist.scss";
import "../src/styles/archive.scss";
import "../src/styles/checkout.v2.scss";
import "./App.css";
import HomePageV2 from "./pages/home/HomePageV2";
import FavoritesPage from "./pages/coleccion/FavoritesPage";
export const AppContext = React.createContext();

function App() {
  const [termsModal, setTermsModal] = useState(false);
  const [zoomModal, setZoomModal] = useState(false);
  const [token, setToken] = useState(null);
  const [isLogged, setIsLogged] = useState(null);
  const [email, setEmail] = useState(null);
  const [stripeActived, setStripeActived] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [successEmail, setSuccesEmail] = useState(false);
  const [configWeb, setConfigWeb] = useState({
    currentPage: ''
  });
  const [cartContext, setCartContext] = useState({
    totalItems: 0,
    showModal: false,
    paymentMethod: ''
  });

  useEffect(() => {
    setToken(
      localStorage.getItem("token")
        ? localStorage.getItem("token").replace(/['"]+/g, "")
        : null
    );
    setEmail(
      localStorage.getItem("email")
        ? localStorage.getItem("email")
        : null
    );
  }, []);

  const RouteTracker = () => {
    const location = useLocation();

    useEffect(() => {
      setConfigWeb((prev) => {
        let newPage = "";
        switch(location.pathname) {
          case "/collection":
            newPage = 'shop';
            break;
          case "/works":
            newPage = 'project';
            break;
          case "/the-artist":
            newPage = 'artist';
            break;
        }
        return prev.currentPage !== newPage ? { ...prev, currentPage: newPage } : prev;
      });
    }, [location.pathname]);

    return null;
  };



  return (
    <AppContext.Provider
      value={{
        configWeb,
        setConfigWeb,
        cartContext,
        setCartContext,
        loginModal,
        setLoginModal,
        termsModal,
        setTermsModal,
        zoomModal,
        setZoomModal,
        token,
        setToken,
        stripeActived,
        setStripeActived,
        setSuccesEmail,
        successEmail,
        isLogged,
        setIsLogged,
        email,
        setEmail
      }}
    >
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fancy Monas" />
        <meta property="og:url" content="https://fancymonas.io/" />
        <meta
          property="og:image:secure_url"
          content="https://artbag-monas.s3.amazonaws.com/image_social.png"
        />
        <meta property="og:image:type" content="image/PNG" />
      </Helmet>
      <Router>
        <ScrollToTop />
        <RouteTracker />
        <Routes>

          <Route element={<MainLayout navbarBackground='navbar-transparent' />}>
            <Route path={routes.home} element={<HomePageV2 />} />
            <Route path={routes.artworkLanding} element={<ArtworkLanding />} />
            <Route path={routes.the_artist} element={<ArtistV2 />} />
          </Route>

          <Route element={<MainLayout />}>
            <Route path={routes.algoritmo} element={<Algoritmo />} />
            <Route path={routes.coleccion} element={<CollectionPage />} />
            <Route path={routes.favorites} element={<FavoritesPage />} />
            <Route path={routes.my_coleccion} element={<MiColeccionPage />} />
            <Route path={routes.redeem} element={<RedeemPage />} />
            <Route path={routes.archive} element={<Archive />} />

            <Route
              path={`${routes.archive_detail}/:archive`}
              element={<ArchiveDetail />}
            />
            <Route
              path={`${routes.authentication}/:code`}
              element={<AuthenticationPage />}
            />
            <Route path={routes.logout} element={<LogoutPage />} />
            {token ? (
              <Route
                path={routes.my_coleccion}
                element={
                  <MainLayout>
                    <MiColeccionPage />
                  </MainLayout>
                }
              />
            ) : null}
          </Route>
          {/* <Route path={routes.homepage} element={<HomePageV2 />} /> */}

          <Route
            path={routes.fancy_checkout}
            element={<FancyCheckoutV2 />}
          />

          <Route
            path={routes.comercial_checkout}
            element={<ComercialCheckout />}
          />
          <Route
            path={`${routes.comercial_checkout}/:paymentMethod`}
            element={<PaymentMethod />}
          />
          <Route path={routes.revelated_list} element={<RevelatedQueue />} />
          <Route
            path={`${routes.revelated_list}/reveal`}
            element={<RemoteReveal />}
          />
          <Route path={routes.sellers_login} element={<SellersLogin />} />

          <Route path={"/ok"} element={<ThanksPage />} />
          <Route path={"/ko"} element={<PaypalError />} />
          <Route path={routes.paypal_success} element={<PaypalSuccess />} />
          <Route path={routes.paypal_error} element={<PaypalError />} />

          <Route path={routes.ok_redeem} element={<ThanksRedeemPage />} />

          <Route element={<MainLayout />}>
            <Route path="*" element={<NotFoundPage />} />
            <Route path={`${routes.items}/:itemNumber`} element={<ItemsPage />} />
          </Route>

          <Route path={routes.success_payment} element={<SuccessPayment />} />

        </Routes>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
