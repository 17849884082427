import * as React from "react";

const TwitterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
  >
    <path
      fill="#fff"
      d="M22.85 5.8a8.5 8.5 0 0 1-2.36.64 4.13 4.13 0 0 0 1.81-2.27 8.2 8.2 0 0 1-2.61 1 4.1 4.1 0 0 0-7 3.74 11.64 11.64 0 0 1-8.45-4.29 4.16 4.16 0 0 0-.55 2.07 4.09 4.09 0 0 0 1.82 3.41 4.05 4.05 0 0 1-1.86-.51v.05a4.1 4.1 0 0 0 3.3 4 4 4 0 0 1-1.1.17 5 5 0 0 1-.77-.07 4.11 4.11 0 0 0 3.83 2.84 8.22 8.22 0 0 1-6.06 1.7 11.57 11.57 0 0 0 6.29 1.85A11.59 11.59 0 0 0 20.85 8.45v-.53a8.4 8.4 0 0 0 2-2.12"
    ></path>
  </svg>
);

export default TwitterIcon;
