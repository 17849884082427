import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import React from "react";

const StyledDropdown = styled(Dropdown)`
  position: relative;
  display: inline-block;
  color: #000;
  font-family: ${(props) => props.theme.font.primaryFont};
`;
const StyledDropdownToggle = styled(Dropdown.Toggle)`
  height: 44px;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  /* box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12); */
  color: #414651;
  font-family: ${(props) => props.theme.font.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border: 1px solid #d5d7da;
  font-weight: bold;

  &.dropdown-toggle::before {
    content: none;
    margin-left: 0px;
  }

  &:hover {
    border: 1px solid #d5d7da;
    background-color: white;
    color: black;
  }
  &:focus {
    border: 1px solid #d5d7da !important;
    background-color: white !important;
    color: black !important;
  }
  &.btn.show {
    color: black;
    background-color: white;
  }
  &.btn:first-child:active {
    background-color: white;
    color: black;
  }
  &.btn:focus-visible {
    background-color: white;
    color: black;
    border: 0;
  }

  .custom-dropdown-gap {
    gap: 10px;
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  height: auto;
  flex-shrink: 0;
  position: absolute;
  top: 125%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
  padding: 0;
  min-width: 100%;
  z-index: 1;
  border: 0;
  color: #414651;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  background-color: #fff;
  cursor: pointer;
  color: #414651 !important;
  text-align: left;
  font-family: ${(props) => `${props.theme.font.primaryFont} !important`};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 16px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

// Componente de Dropdown personalizado
const CustomDropdown = ({ items, startIcon, endIcon, text }) => {
  const navigate = useNavigate();

  return (
    <StyledDropdown>
      <StyledDropdownToggle id="dropdown-basic">
        {startIcon && startIcon} {text} {endIcon && endIcon}
      </StyledDropdownToggle>

      <StyledDropdownMenu>
        {items.map((item, index) => (
          <StyledDropdownItem
            key={index}
            onClick={() => navigate(item.href)}
          >
            {item.name}
          </StyledDropdownItem>
        ))}
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

export default CustomDropdown;
