import React, { useState, useEffect, useRef, useCallback } from "react";
import { getArtworksWithFilters } from "../../services/axiosService";
import routes from "../../routes/routes";
import ArtworksGrid from "../../components/collection/ArtworksGrid";
import FiltersComponent from "../../components/collection/Filters";
import { useCollectionContext } from "../../context/CollectionContext";

export default function ColeccionPage() {
  const { checkboxValues, offsetArtworks, setOffsetArtworks, gridSize, availableOnly, setAvailableOnly } = useCollectionContext();
  const [findArtworks, setFindArtworks] = useState([]);
  const noResultsContainerRef = useRef(null);
  const firstRenderRef = useRef(true);
  const nextRequest = useRef(true);
  const [showNextRender, setShowNextRender] = useState(true);
  const nextRenderRef = useRef(null);

  useEffect(() => {
    setFindArtworks([]);
    setOffsetArtworks(0);
    nextRequest.current = true;
    setShowNextRender(true);
    fetchArtworks(gridSize * 3, 0, checkboxValues);
  }, [checkboxValues, gridSize, availableOnly]);

  const fetchArtworks = useCallback(async (fetchLimit, fetchOffset, checkboxValues) => {
    if (!nextRequest.current) return;

    const filters = { ...checkboxValues, status: availableOnly ? ["available"] : [] };

    try {
      const response = await getArtworksWithFilters(filters, fetchOffset, fetchLimit);

      setFindArtworks((prevArtworks) => {
        if (firstRenderRef.current) {
          firstRenderRef.current = false;
          setOffsetArtworks(response.data.offsetArtworks);
          return response.data.artworks;
        }

        if (response.data.artworks.length > 0) {
          const existingIds = new Set(prevArtworks.map((artwork) => artwork.id));
          const newArtworks = response.data.artworks.filter((artwork) => !existingIds.has(artwork.id));

          if (newArtworks.length > 0) {
            setOffsetArtworks(response.data.offsetArtworks);
            return [...prevArtworks, ...newArtworks];
          }
        }

        return prevArtworks;
      });

      nextRequest.current = response.data.artworks.length > 0;
      setShowNextRender(response.data.artworks.length > 0);
    } catch (error) {
      console.error("Error fetching artworks:", error);
      setFindArtworks([]);
    }
  }, [setOffsetArtworks, availableOnly]);

  const loading = useRef(false);
  useEffect(() => {
    const nextRenderHandle = () => {
      if (loading.current) return;
      if (nextRenderRef.current) {
        const { top } = nextRenderRef.current.getBoundingClientRect();
        if (top < window.innerHeight * 1.5) {
          loading.current = true;
          fetchArtworks(gridSize, offsetArtworks, checkboxValues).then(() => {
            loading.current = false;
          });
        }
      }
    };

    window.addEventListener("scroll", nextRenderHandle);

    return () => {
      window.removeEventListener("scroll", nextRenderHandle);
    };
  }, [checkboxValues, gridSize, offsetArtworks, fetchArtworks]);

  const handleToggle = (e) => {
    setAvailableOnly(!availableOnly);
  };

  return (
    <>
      <div className="coleccion-page">

        <div className="all-filters-container">
          <FiltersComponent/>
          <section className="section-availability d-flex">
            <div className="disponibility-filter-container">
              <div className={`switch-wrapper ${availableOnly ? "active" : ""}`}>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={availableOnly}
                    name="status"
                    value="available"
                    onChange={() => handleToggle()}
                  />
                  <span className="slider"></span>
                </label>
                <span className="filter-label">Disponibles</span>
              </div>

              <div className={`status-indicators mdHide ${availableOnly ? "d-none" : ""}`}>
                <div className="d-flex align-items-center">
                  <span className="status-dot red"></span> <span>Vendida</span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="status-dot orange"></span> <span>Reservada</span>
                </div>
              </div>
            </div>
          </section>
        </div>


        <ArtworksGrid artworks={findArtworks} gridSize={gridSize} routes={routes} />

        {showNextRender && <aside className="next-render" ref={nextRenderRef}></aside>}
        {findArtworks.length === 0 && !showNextRender &&
          <aside
          className="d-flex justify-content-center align-items-center no-results"
          ref={noResultsContainerRef}
          >
            <img src="images/collection/collection-no-result.png" alt="no results found" />
            <div>
              <h2>Oops!</h2>
              <p>
                No es posible mostrar una Fancy Mona con los filtros que
                seleccionaste.
              </p>
              <p>Prueba ingresando otra combinación.</p>
            </div>
          </aside>
        }
      </div>
    </>
  );
}
