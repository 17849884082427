import { v4 as uuidv4 } from 'uuid';
import apiClient from "./apiClientService";

class CartService {
  constructor(setCartContext = null) {
    if(setCartContext)
      this.setCartContext = setCartContext;
  }

  setExpiryAt() {
    const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    return new Date().getTime() + oneDay;
  }

  getCartLocalStorage() {
    if (!localStorage.getItem('cart')) {
      return null;
    }
    const currentDateTime = new Date().getTime();
    let cart = JSON.parse(localStorage.getItem('cart'));

    if (cart.session.expiryAt < currentDateTime) {
      if(this.setCartContext) {
        this.setCartContext(prevState => ({
          ...prevState,
          totalItems: 0
        }));
      }
      return null;
    }
    return cart;
  }

  async getSessionCart(discountCode = null) {

    try {

      if(!this.setCartContext) {
        throw new Error('instantiate the cartService class with setCartContext');
      }

      const cart = this.getCartLocalStorage();
      const token = !cart ? uuidv4() : cart.session.token;
      let setDiscountCode = !cart ? '' : cart.session.discountCode;
      if(discountCode)
        setDiscountCode = discountCode;

      const response = await apiClient.get('/cart', { params: { token, discountCode: setDiscountCode } });

      const updatedCart = {
        data: response.data,
        session: {
          token: token,
          discountCode: setDiscountCode,
          expiryAt: this.setExpiryAt()
        }
      };

      this.setCartContext(prevState => ({
        ...prevState,
        totalItems: updatedCart.data.totalItems
      }));

      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async addItemToCart(artworkId) {
    try {
      let cart = this.getCartLocalStorage();
      if(!cart) {
        cart = await this.getSessionCart();
      }
      await apiClient.post('/cart', { token: cart.session.token, artworkId, displayQuantity: 0 });

      return await this.getSessionCart();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async removeItemFromCart(itemId) {
    try {
      const cart = this.getCartLocalStorage();
      if (!cart) return await this.getSessionCart();

      await apiClient.delete('/cart', { params: { token: cart.session.token, id: itemId } });

      return await this.getSessionCart();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async checkout(personalData, billingData, paymentMethod) {
    try {
      const cartSession = this.getCartLocalStorage();

      let data = {
        token: cartSession.session.token,
        discountCode: '',
        email: personalData.email,
        country: personalData.country || 'Argentina',
        doesAcceptNotifications: (personalData.doesAcceptNotifications == '1') ? true : false,
        shippingInfo: {
          name: personalData.name,
          surname: personalData.surname,
          phone: personalData.phone,
          address: personalData.address_street,
          number: personalData.address_number,
          floorNumber: personalData.address_floor,
          dpto: personalData.address_departament,
          city: personalData.address_city,
          state: personalData.address_province,
          postalCode: personalData.address_postalcode,
          dni: personalData.document_number,
          additionalIndications: personalData.comment
        },
        isSameBillingInfo: (personalData.equalBillingData == '0') ? false : true
      };

      let setBillingData = personalData;
      if(!data.isSameBillingInfo) {
        setBillingData = billingData;
      }

      data['billingInfo'] = {
        billingName: setBillingData.name,
        billingSurname: setBillingData.surname,
        billingPhone: setBillingData.phone,
        billingAddress: setBillingData.address_street,
        billingNumber: setBillingData.address_number,
        billingFloor: setBillingData.address_floor,
        billingDpto: setBillingData.address_departament,
        billingCity: setBillingData.address_city,
        billingState: setBillingData.address_province,
        billingPostalCode: setBillingData.address_postalcode
      };

      const response = await apiClient.post(`/checkout/${paymentMethod}`, data);

      /* remove session cart */
      localStorage.removeItem('cart');

      /* close modal and set total items = 0 */
      this.setCartContext({
        showModal: false,
        totalItems: 0
      });

      return {
        status: true,
        data: response.data,
        httpCode: response.status
      }
    } catch(error) {
      console.error(error?.response?.data || error);
      return {
        status: false,
        data: {},
        httpCode: error.response?.status
      }
    }
  }

  async validateDiscountCode(discountCode) {

    try {
      const response = await apiClient.get(`/cart/validate-discount-code?discountCode=${discountCode}`);
      await this.getSessionCart(discountCode);

      return {
        status: true,
        data: response.data,
        httpCode: response.status
      };
    } catch(error) {
      console.error(error);
      return {
        status: false,
        data: {},
        httpCode: error.response?.status || 500
      }
    }
  }

  async associateCart() {
    try {
      let cart = this.getCartLocalStorage();
      if(!cart) {
        cart = await this.getSessionCart();
      }
      await apiClient.post("/cart/associate", { token: cart.session.token });
      return await this.getSessionCart();
    } catch (error) {
      console.error("Error associating cart", error);
      return null;
    }
  }
}

export default CartService;
