import React, { useEffect, useState, useRef } from "react";
import { AppContext } from "../../App";
import { useNavigate } from "react-router-dom";

/* services */
import CartService from "../../services/cart.service";

/* assets */
import CloseIconV2 from "../icons/CloseIcon.v2";
import GiftIcon from "../icons/GiftIcon";
import TrashIcon from "../icons/TrashIcon";
import PercentageIcon from "../icons/PercentageIcon";
import "../../styles/modalCart.scss";

function ModalCart({ checkoutClassName = null, handleContinue = null }) {

  const navigate = useNavigate();
  const { cartContext, setCartContext } = React.useContext(AppContext);


  const [discountCode, setDiscoundCode] = useState({
    showInput: false, // show input discount code
    inputCode: '', // set value input discountCode, yet to be verified
    validCode: null, // set a code that is already verified,
    discountName: null,
    discountValue: null
  });
  const [totalItems, setTotalItems] = useState(0);
  const [items, setItems] = useState([]);
  const [price, setPrice] = useState(0);

  const cartContainerRef = useRef(null);
  const modalRef = useRef(null);
  const cartService = new CartService(setCartContext);

  const setCartParams = () => {
    const cartSession = cartService.getCartLocalStorage();
    if (cartSession) {
      setTotalItems(cartSession.data?.totalItems);
      setItems(cartSession.data.cart);
      setPrice(cartSession.data.total);
      setCartContext((prevState) => ({
        ...prevState,
        totalItems: cartSession.data?.totalItems,
      }));

      if(cartSession.data.discount?.amount) {
        let discountName = cartSession.data.discount.amount * - 1;
        if(cartSession.data.discount.type == 'percentage') {
          discountName += '%';
        }

        setDiscoundCode((prevState) => ({
          ...prevState,
          validCode: cartSession.session.discountCode,
          discountName: discountName,
          discountValue: cartSession.data.discount.total * -1
        }));
      }
    }
  };

  const handleCloseModal = () => {
    if (checkoutClassName) {
      navigate("/collection");
      return;
    }
    if (cartContainerRef.current) cartContainerRef.current.classList.remove("show");
    if (modalRef.current) modalRef.current.classList.remove("bg");

    setTimeout(() => {
      setCartContext((prevState) => ({
        ...prevState,
        showModal: false,
      }));
    }, 200);
  };

  const handleValidateDiscountCode = async () => {
    const response = await cartService.validateDiscountCode(discountCode.inputCode);
    if(!response.status || !response?.data?.valid) {
      alert('El código no es correcto');
      return;
    }
    setCartParams();
  }

  useEffect(() => {
    setCartContext((prev) => ({
      ...prev,
      paymentMethod: ''
    }));
  }, [])

  useEffect(() => {
    setCartParams();

    if (cartContext.showModal) {
      setTimeout(() => {
        if (cartContainerRef.current) cartContainerRef.current.classList.add("show");
        if (modalRef.current) modalRef.current.classList.add("bg");
      }, 100);

      const handleClickOutside = (event) => {
        if (
          modalRef.current &&
          !cartContainerRef.current.contains(event.target)
        ) {
          handleCloseModal();
        }
      };

      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          handleCloseModal();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [cartContext.showModal]);

  return (
    <div
      className={`modalCart justify-content-end w-100 h-100 ${
        cartContext.showModal ? "show bg" : ""
      } ${checkoutClassName ? checkoutClassName : ""}`}
      ref={modalRef}
    >
      <div className="cart d-flex flex-column" ref={cartContainerRef}>
        <div style={{ flex: "60%" }}>
          {/** title and btn close */}
          <div className="d-flex align-items-center w-100">
            <div className="w-50">
              <p className="title d-flex align-items-center mb-0">
                Mi compra <span className="badge">{totalItems}</span>
              </p>
            </div>
            <div className="w-50 d-flex justify-content-end">
              <button type="button" onClick={handleCloseModal} className="btnClose">
                <CloseIconV2 />
              </button>
            </div>
          </div>

          {/** offer layout if totalItems < 8 */}
          {totalItems < 8 && (
            <div className="offerLayout mt-2">
              <p className="mb-0">
                Comprando de 4 a 7 obras, <b>el valor de la obra es de $2200 USD</b>
                <br />A partir de 8 obras, <b>el valor es de $2000 USD por obra</b>
              </p>
              <div className="gift d-flex justify-content-center align-items-center">
                <GiftIcon />
              </div>
            </div>
          )}

          {totalItems > 0 && (
            <>
              {/** cart, items list */}
              <div className="row itemsList">
                {Object.keys(items).map((key, index) => (
                  <div
                    key={index}
                    className="col-12 d-flex align-items-center justify-content-between item mt-4"
                  >
                    <div className="col-auto">
                      <img
                        src={items[key].artwork.compressedImageUrl}
                        className="img-fluid"
                        style={{ maxWidth: "50px" }}
                      />
                    </div>

                    <div className="col text-center">
                      <p className="mb-0 fancyNumber">
                        Fancy Mona #{items[key].artwork.number}
                      </p>
                    </div>

                    <div className="col">
                      <p className="mb-0 fancyPrice">{items[key].price} USD</p>
                    </div>

                    <div className="col-auto">
                      <button
                        type="button"
                        className="btnRemove"
                        onClick={async () => {
                          await cartService.removeItemFromCart(items[key].id);
                          setCartParams();
                        }}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {/** fine print art */}
              <div className="finePrintArt mt-5">
                <div className="badge">Incluido</div>
                <p className="title mb-1">Fine art print</p>
                <p className="description mb-0">
                  Una Imagen fotográfica de 60 x 60 cm impresa en papel Hahnemühle® Photo Rag UltraSmooth® de 300 g/m². *Marco incluído sólo para Argentina.
                </p>
              </div>

              {/** discount code */}
              <div className="discount mt-3">
                <div className="d-flex align-items-center">
                  <div className="percentage">
                    <PercentageIcon />
                  </div>
                  <div className="w-100">
                    { discountCode.validCode && (
                      <div>
                        <span style={{marginLeft: "12px"}}>{discountCode.validCode}</span>
                      </div>
                    )}
                    { !discountCode.validCode && discountCode.showInput && (
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="col-7">
                          <input type="text" className="form-control" placeholder="Escribe código" onChange={(e) => {
                            setDiscoundCode((prevState) => ({ ...prevState, inputCode: e.target.value }));
                          }} />
                        </div>
                        <div className="col-5 text-center">
                          <p className="text mb-0" onClick={handleValidateDiscountCode}><span>Aplicar</span></p>
                        </div>
                      </div>
                    )}
                    { !discountCode.validCode && !discountCode.showInput && (
                      <p className="mb-0 text">
                        ¿Tienes un código de descuento? <span onClick={()=>{
                          setDiscoundCode((prevState) => ({ ...prevState, showInput: true }));
                        }}>Ingresar</span>
                      </p>
                    )}
                  </div>
                </div>
                { discountCode.validCode && (
                  <div className="d-flex align-items-center mt-4">
                    <div className="col-6">
                      <b>Descuento:</b> {discountCode.discountName}
                    </div>
                    <div className="col-6" style={{ textAlign: 'right'}}>
                      {discountCode.discountValue} USD
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div style={{ flex: "40%" }} className="row align-items-end">
          <div>
            <div className="d-flex priceContainer align-items-center w-100">
              <div className="col-5">
                <p className="total">Total</p>
              </div>
              <div className="col-7">
                <p className="price">{price} USD</p>
              </div>
            </div>
            <div className="mt-4">
              {totalItems > 0 && (
                <>
                  {cartContext.paymentMethod == 'paypal' && handleContinue !== null && (
                    <button type="button" className="btnContinue btnPaypal w-100" onClick={() => {
                      if (handleContinue) handleContinue();
                      else {
                        navigate("/fancy-checkout");
                        setCartContext((prevState) => ({ ...prevState, showModal: false }));
                      }
                    }}>
                      <img src="/images/icons/icon-paypal.jpg" /> Pagar a través de Paypal
                    </button>
                  )}

                  {cartContext.paymentMethod == 'mercadopago' && handleContinue !== null && (
                    <button type="button" className="btnContinue btnMercadopago w-100" onClick={() => {
                      if (handleContinue) handleContinue();
                      else {
                        navigate("/fancy-checkout");
                        setCartContext((prevState) => ({ ...prevState, showModal: false }));
                      }
                    }}>
                      <img src="/images/icons/icon-mercadopago.jpg" /> Pagar a través de Mercadopago
                    </button>
                  )}

                  {(!['mercadopago', 'paypal'].includes(cartContext.paymentMethod) && handleContinue !== null || handleContinue === null) && (
                    <button type="button" className="btnContinue w-100" onClick={() => {
                      if (handleContinue) handleContinue();
                      else {
                        navigate("/fancy-checkout");
                        setCartContext((prevState) => ({ ...prevState, showModal: false }));
                      }
                    }}>
                      Continuar
                    </button>
                  )}

                </>
              )}
              <button type="button" className="btnShopping w-100 mt-3" onClick={handleCloseModal}>
                Seguir comprando
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalCart;
