import React, { useEffect, useRef, useState } from "react";
import styles from "./SectionHome3.module.scss";
import "react-multi-carousel/lib/styles.css";
import InfoCircleIcon from "../../components/icons/InfoCircleIcon";
import anime from "animejs/lib/anime.es.js";
import Modal from "./Modal";

const SectionHome3 = () => {
  const [open, setOpen] = useState(false);
  const scrollContainerRef = useRef(null);
  const [infoModal, setInfoModal] = useState(undefined);

  const infoModal1 = {
    id: 1,
    image: "https://artbag-monas.s3.amazonaws.com/MonaModalHome1.png",
    modal: {
      title: "DISPLAY",
      description:
        "Pantalla de 12.3 pulgadas de alta calidad con diseño de marco de Edgardo Giménez.",
    },
  };
  const infoModal2 = {
    id: 2,
    image: "https://artbag-monas.s3.amazonaws.com/Cuadro_monas.png",
    modal: {
      title: "FINE ART PRINT",
      description:
        "Una Imagen fotográfica de 60 x 60 cm impresa en papel Hahnemühle® Photo Rag UltraSmooth® de 300 g/m². *Marco incluído sólo para Argentina.",
    },
  };

  const handleOpen = (value) => {
    if (value === 1) {
      setInfoModal(infoModal1);
    }

    if (value === 2) {
      setInfoModal(infoModal2);
    }

    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setInfoModal(undefined);
  };

  useEffect(() => {
    anime({
      targets: ".animateCircle",
      translateY: 10,
      direction: "alternate",
      loop: true,
      easing: "easeInOutSine",
    });
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 390;
    }
  }, []);

  return (
    <div
      className={`${styles.Container} section2ScrollContainer`}
      ref={scrollContainerRef}
    >
      <div className={styles.ContainerImage}>
        <img
          className={`${styles.ImageRoom} ease-in-out`}
          src="/homepage/room.png"
          alt="imageroom"
        />
      </div>
      <div className={`${styles.point1}`}>
        <InfoCircleIcon onClick={() => handleOpen(2)} />
        <InfoCircleIcon onClick={() => handleOpen(1)} />
      </div>

      {open && infoModal && (
        <Modal open={open} data={infoModal} onClose={onClose} />
      )}
    </div>
  );
};

export default SectionHome3;
