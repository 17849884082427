import { createContext, useState, useContext } from "react";

const CollectionContext = createContext();

export function CollectionProvider({ children }) {
  const defaultCheckboxValues = {
    'Fondo': [],
    'Pelaje': [],
    'Ojos': [],
    'Boca': [],
    'Accesorios': [],
    'Traje': [],
    'Sombrero': [],
    'order': [],
    'status': []
  };

  const [offsetArtworks, setOffsetArtworks] = useState(0);
  const [checkboxValues, setCheckboxValues] = useState(defaultCheckboxValues);
  const [gridSize, setGridSize] = useState(3);
  const [availableOnly, setAvailableOnly] = useState(false);

  return (
    <CollectionContext.Provider value={{ 
      offsetArtworks, 
      setOffsetArtworks, 
      checkboxValues, 
      setCheckboxValues,
      defaultCheckboxValues,
      gridSize,
      setGridSize,
      availableOnly,
      setAvailableOnly }}>
      {children}
    </CollectionContext.Provider>
  );
}

export function useCollectionContext() {
  return useContext(CollectionContext);
}
