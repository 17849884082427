import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getFavorites } from "../../services/favorites.service";
import { getCollection } from "../../services/axiosService.js";
import routes from "../../routes/routes";
import ArtworksGrid from "../../components/collection/ArtworksGrid";
import ChevronLeft from "../../components/icons/ChevronLeft.v2.jsx";
import { AppContext } from "../../App.js";

const gridSize = 5;
const myCollectionGridSize = 3;

export default function FavoritesPage() {
  const { token } = React.useContext(AppContext);
  const [findArtworks, setFindArtworks] = useState([]);
  const [myCollection, setMyCollection] = useState([]);
  const [favDeleted, setFavDeleted] = useState(null);
  const noResultsContainerRef = useRef(null);
  const isFetchingRef = useRef(false);
  const nextRender = useRef(null);
  const offsetArtworksRef = useRef(0);
  const nextRequestRef = useRef(true);
  const noResultsRef = useRef(false);

  const fetchArtworks = async (limit, offset, firstCall = false) => {
    if (!nextRequestRef.current || isFetchingRef.current) return;
  
    isFetchingRef.current = true;

    try {
      const response = await getFavorites(limit, offset);

      if (!response.data?.favorites?.length && firstCall) {
        nextRequestRef.current = false;
        noResultsRef.current = true;
        setFindArtworks([]);
        return;
      } else if (response.data?.favorites?.length < gridSize) {
        nextRequestRef.current = false;
      }
  
      offsetArtworksRef.current = response.data?.offsetArtworks;
  
      setFindArtworks((prevArtworks) => [
        ...prevArtworks,
        ...response.data.favorites.map(fav => fav.Artwork)
      ]);

    } catch (error) {
      setFindArtworks([]);
      nextRequestRef.current = false;
    } finally {
      setTimeout(() => { isFetchingRef.current = false; }, 500);
    }
  };

  const fetchMyCollectionArtworks = async () => {
    try {
      const myCollection = await getCollection();
      setMyCollection(Array.isArray(myCollection?.data) ? myCollection.data : []);
    } catch (error) {
      setMyCollection([]);
    }
  };

  useEffect(() => {
    if(myCollection.length === 0 && token){
      fetchMyCollectionArtworks();
    }

    if (findArtworks.length === 0) {
      offsetArtworksRef.current = 0;
      nextRequestRef.current = true;
      noResultsRef.current = false;
      fetchArtworks(gridSize * 3, offsetArtworksRef.current, true);
    }
  }, []);

  useEffect(() => {
    if(token){
      fetchMyCollectionArtworks();
    }
  }, [token]);

  useEffect(() => {
      setFindArtworks([]);
      offsetArtworksRef.current = 0;
      nextRequestRef.current = true;
      noResultsRef.current = false;
      fetchArtworks(gridSize * 3, offsetArtworksRef.current, true);
  }, [favDeleted]);

  useEffect(() => {
    const nextRenderHandle = () => {
      if (!nextRequestRef.current || isFetchingRef.current) return;

      const container = nextRender.current;
      if (!container) return;

      const containerTop = container.offsetTop;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      if (scrollTop + windowHeight >= containerTop) {
        fetchArtworks(gridSize, offsetArtworksRef.current);
      }
    };

    window.addEventListener("scroll", nextRenderHandle);
    return () => window.removeEventListener("scroll", nextRenderHandle);
  }, []);

  return (
    <>
    {myCollection.length > 0 && (
      <div className="coleccion-page">
        <div className="mdHide lgFlex align-items-center">
          <ChevronLeft />
          <Link className="linkBread secondaryFont colorAqua fw-bold" to={routes.coleccion}>
            Volver al shop
          </Link>
        </div>
        <div className="mdHide">
          <h1 className="primaryFont titulo-item">MI COLECCIÓN</h1>
        </div>
        <ArtworksGrid artworks={myCollection} gridSize={myCollectionGridSize} marginLess={true} />
      </div>
    )}
    <div className={`coleccion-page ${myCollection.length > 0 ? "coleccion-page-second" : ""}`}>
      {findArtworks.length === 0 && noResultsRef.current ? (
        <aside className="no-results" ref={noResultsContainerRef}>
          <span role="img" aria-label="monkey covering eyes">🙈</span>
          <h2>¡Aún no has hecho tu selección de Fancy Monas!</h2>
          <p>Puedes ir al Shop, buscar tus favoritas y comprar!</p>
          <Link to={routes.coleccion} className="shop-button">Shop</Link>
        </aside>
      ) : (
        <>
          {myCollection.length === 0 && (
            <div className="mdHide lgFlex align-items-center">
              <ChevronLeft />
              <Link className="linkBread secondaryFont colorAqua fw-bold" to={routes.coleccion}>
                Volver al shop
              </Link>
            </div>
          )}
          <div className="mdHide">
            <h1 className="primaryFont titulo-item">FAVORITAS</h1>
          </div>
          <ArtworksGrid artworks={findArtworks} gridSize={gridSize} setFavsDeleted={setFavDeleted}/>
          {nextRequestRef.current && <aside className="next-render" ref={nextRender}></aside>}
        </>
      )}
    </div>
    </>
  );
}
