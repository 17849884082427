import "../../styles/styles.scss"
import CheckNok from "../../components/icons/CheckNok";

const PaypalError = () => {
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <div>
        <CheckNok width="100" height="auto" color="#e3948b" />
      </div>
      <br />
      <h3 style={{ fontFamily: 'AlteHaasGroteskBold' }}>No se ha podido realizar la transacción.</h3>
    </div>
  );
};

export default PaypalError;
